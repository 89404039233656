<template>
  <transition name="notice">
    <div v-if="visible" :class="'notice notice-'+type">
      <div class="notice-heading">
        <span class="notice-icon" v-html="getSVG(getNoticeIcon)"/>
        <span v-html="heading"/>
        <span class="notice-close" @click="closeArea" v-html="getSVG('times')"/>
      </div>
      <div class="notice-content">
        <div class="notice-text" v-html="content"></div>
        <button v-if="buttonText&&buttonText!==''"
                :class="'button button-notice button-secondary button-notice-'+type"
                @click="buttonAction"
                v-html="buttonText"
        />
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  data() {
    return {
      timeout: null,
    }
  },
  props: [
    "model",
  ],
  watch: {
    model(val, ignored_oldVal) {
      if (this.timeout !== null) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      if (val.visible) {
        this.timeout = setTimeout(() => {
          this.closeArea();
          this.timeout = null;
        }, 5000);
      }
    }
  },
  computed: {
    cleanedModel() {
      if (typeof this.model === "object" && this.model !== null) {
        return this.model;
      } else {
        return {};
      }
    },
    type() {
      switch (this.cleanedModel.type) {
        case "success":
          return "success";
        case "warning":
          return "warning";
        case "error":
          return "error";
        case "info":
        default:
          return "info";
      }
    },
    heading() {
      if (this.cleanedModel.heading) {
        return this.cleanedModel.heading;
      }
      switch (this.type) {
        case "info":
          return "Information";
        case "success":
          return "Abgeschlossen";
        case "warning":
          return "Achtung";
        case "error":
          return "Kritischer Fehler";
      }
    },
    content() {
      if (this.cleanedModel.content) {
        return this.cleanedModel.content;
      }
      return '';
    },
    visible() {
      return this.cleanedModel.visible === true;
    },
    buttonText() {
      if (this.cleanedModel.buttonText) {
        return this.cleanedModel.buttonText;
      }
      return '';
    },
    buttonAction() {
      if (typeof this.cleanedModel.buttonAction === "function") {
        return this.cleanedModel.buttonAction;
      }
      return () => {
      };
    },
    getNoticeIcon() {
      switch (this.type) {
        case "info":
          return "info";
        case "success":
          return "success";
        case "warning":
        case "error":
          return "warning";
      }
    }
  },
  methods: {
    closeArea() {
      this.model.visible = false;
      if (this.timeout !== null) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
    }
  }
}
</script>