<template>
  <div :id="'ticket-details-accordion-'+this.id" :class="'ticket-details-accordion standard-margin-bottom' + (this.class  ?? '')">
    <div @click="openContainer" class="ticket-details-accordion-title standard-margin-bottom prevent-select">
      <span class="ticket-details-accordion-title-caret" v-html="getSVG('caret_down')" />
      <h4 class="ticket"><span>{{ this.title }}</span></h4>
    </div>
    <div class="ticket-details-accordion-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import {v4 as uuid4} from "uuid";
import {nextTick} from "vue";

export default {
  name: "TicketDetailsAccordion",
  data() {
    return {
      id: null,
      closing: false,
    }
  },
  created() {
    this.id = uuid4();
  },
  async mounted() {
    await nextTick();
    if(this.closed) {
      let target = document.querySelector('#ticket-details-accordion-' + this.id);
      target.classList.add('closed');
      this.open = false;
    } else {
      this.open = true;
    }
  },
  props: [
    "title",
    "closed",
    "class"
  ],
  methods: {
    openContainer() {
      if(this.closing) {
        return;
      }
      let target = document.querySelector('#ticket-details-accordion-' + this.id);
      let container = target.querySelector(".ticket-details-accordion-content");
      container.style.height = "auto";
      const height = container.clientHeight + "px";
      this.closing = true;
      setTimeout(() => {
        this.closing = false;
      }, 500);
      if(this.open) {
        this.open = false;
        container.style.height = height;
        setTimeout(() => {
          container.style.height = '0px';
        }, 0);

        target.classList.add('closed');
      } else {
        this.open = true;
        container.style.height = "0px"
        setTimeout(() => {
          container.style.height = height;
        }, 0);
        setTimeout(() => {
          container.style.height = 'auto';
        }, 500);
        target.classList.remove('closed');

      }
    }
  },
}
</script>