// External modules
import {createApp} from 'vue';
import store from './modules/store/index';
import cookies from 'vue-cookies';

// App-file
import App from './App.vue';
import mitt from 'mitt'

// Mixins
import functionsMixin from './modules/mixin/functions';
import configMixin from './modules/mixin/config';
import loginMixin from './modules/mixin/login';
import ticketAttributesMixin from './modules/mixin/ticketAttributes';
import dropDownActions from './modules/mixin/dropDownActions';
import wikiActions from "@/modules/mixin/wikiActions";

// Router
import createRouter from './modules/router/index';

// CSS
import './assets/css/login.css';
import './assets/css/style.css';
import './assets/css/satoshi/css.css';
import 'leaflet/dist/leaflet.css';



// Create app and configure router, mixins, plugins, etc.
const app = createApp(App).use(store);
const emitter = mitt();

const router = createRouter(app);
app.use(router);

app.config.globalProperties.emitter = emitter

app.use(cookies)
    .mixin(configMixin)
    .mixin(functionsMixin)
    .mixin(dropDownActions)
    .mixin(loginMixin)
    .mixin(ticketAttributesMixin)
    .mixin(wikiActions)
    .mount('#app');