const route = {
    path: '/calendar',
    children: [
        {
            path: '',
            components: {
                default:
                    () => import(/* webpackChunkName: "calendar" */ '../../components/views/calendar.vue'),
                Sidebar:
                    () => import(/* webpackChunkName: "calendar" */ '../../components/static/subMenu/calendar.vue'),
            }
        }
    ],
}

module.exports = route;