<template>
  <div :class="className">
    <select :id="'selectBox_'+id"
            :data-enum="getEnum"
            :data-max="max"
            :data-min="min"
            :data-not-in="notIn"
            :name="name"
            class="input input-select"
            @change="validate"
            @focusout="validate"
    >
      <option :selected="!selected" disabled="disabled" value="">Bitte wählen...</option>
      <option v-for="option in options" :selected="selected===option.id" :value="option.id">{{ option.title }}</option>
    </select>
    <label :for="'selectBox_'+id">{{ placeholder }}</label>
    <span class="select-arrow" v-html="getSVG('selectTriangle')"/>
  </div>
</template>
<script>
import {v4 as uuid4} from "uuid";
import {nextTick} from "vue";

export default {
  data() {
    return {
      id: null,
      valid: true,
    }
  },
  mounted() {
    this.id = uuid4();
  },
  props: [
    "name",
    "class",
    "options",
    "placeholder",
    "selected",
    "min",
    "max",
    "enum",
    "notIn",
  ],
  methods: {
    async validate(event) {
      await nextTick();

      this.globalValidate(event);

      const el = event.target;
      const value = el.value;

      this.$emit("valueChanged", value, event);

      let valid = true;
      if (this.min !== undefined) {
        if (value.length < this.min) {
          valid = false;
        }
      }
      if (this.max !== undefined) {
        if (value.length > this.max) {
          valid = false;
        }
      }
      if (this.enum !== undefined) {
        if (this.enum.includes(value)) {
          valid = false;
        }
      }
      if (this.notIn !== undefined) {
        if (this.notIn.includes(value)) {
          valid = false;
        }
      }
      this.valid = valid;
    }
  },
  computed: {
    className() {
      let className = this.class ?? '';
      if (this.valid !== undefined && !this.valid) {
        className += ' invalid';
      }
      return className + ' input-wrapper select-wrapper';
    },
    getEnum() {
      return this.enum;
    },
  }
}
</script>