const route = {
    path: '/houses',
    children: [
        {
            path: '',
            components: {
                default:
                    () => import(/* webpackChunkName: "houses" */ '../../components/views/houses.vue'),
                Sidebar:
                    () => import(/* webpackChunkName: "houses" */ '../../components/static/subMenu/houses.vue'),
            }
        },
        {
            path: ':id',
            components: {
                default:
                    () => import(/* webpackChunkName: "house" */ '../../components/views/houses/house.vue'),
                Sidebar:
                    () => import(/* webpackChunkName: "houses" */ '../../components/static/subMenu/houses.vue'),
            }
        }
    ],
}

module.exports = route;