<template>


  <form @submit.prevent="pushEntry" id="manageEntryForm">
    <PartedPageDynamic v-if="(!this.id && this.categories.length > 0) || this.data !== null">

      <div v-if="edit" class="knowledge-text-area knowledge-text-input">
        <div class="knowledge-text-area knowledge-text-area-shorten">
          <TextInput placeholder="Titel"
                     name="title"
                     :value="this.data?.title ?? ''"
                     class="knowledge-wide"
          />

          <WYSIWYGEditor
              :value="this.data?.content ?? this.content"
              name="content"
              :headers="true"
              class="knowledge-wide"
              @updateContent="updateContent"
          />
        </div>


        <div class="entry-search-wrapper">
          <div class="two-buttons">
            <SecondaryButton text="Bearbeitung Beenden" @click="pushEntry"/>
            <NegativeButton text="Löschen" @click="deleteWiki"/>
          </div>
        </div>
      </div>

      <div v-else>
        <div class="knowledge-read-content knowledge-text-input">
          <h3 class="knowledge-entry-header" id="knowledge-header">{{ this.data?.title }}</h3>
          <div class="knowledge-edit-buttons">
            <span v-html="getSVG('edit_new')" class="knowledge-edit-svg" @click="edit = !edit"/>
            <span v-html="getSVG('delete')" class="knowledge-delete-svg" @click="deleteWiki"/>
          </div>
          <div class="wide" id="knowledge-content" v-html="this.data?.content"/>
        </div>
      </div>

      <div class="knowledge-documents" v-show="this.id">
        <h4>Angefügte Dokumente</h4>
        <div
            class="knowledge-list-half"
            v-if="this?.files.length>0"
        >

          <DynamicTable
              :count="5"
              :buttons="[
                                {
                                  icon:'delete',
                                  action: this.deleteFile,
                                },

                              ]"
              :columns="['title']"
              :data="files"
              :column-names="{
                                    title:'Dateien',
                                }"
              :select-options="[
                                  {
                                    name:'Löschen',
                                    action: this.deleteMultipleFiles,
                                  },
                              ]"
              v-if="data!==null&&data!==false"
          />

        </div>

        <div class="knowledge-file-info-distance">
          <form id="manageFileForm" @submit.prevent="pushEntry" v-if="this.id">
            <FileUploadTable
                link-type="wiki"
                :link-id="this.id"
                :save-temporary="!this.id"
                ref="wikiFileTable"
                v-show="this.id"
            />
          </form>
        </div>

        <NoticeArea type="info" class="knowledge-info-files">
          Keine Dateien angehängt
        </NoticeArea>
      </div>
    </PartedPageDynamic>
  </form>

  <PartedPageStatic
      class="knowledge-sidemenu"
      v-if="(this.loaded && this.loadedObjects && this.loadedCustomers) || !this.id"
  >
    <h3 class="knowledge-sidebar-header" >Informationen</h3>
    <div class="knowledge-user-info-sidebar" >
      <template v-if="this.loaded || !this.data">
        <h3 class="knowledge-sidebar-header">Ersteller</h3>
        <div class="knowledge-creator-wrapper">
          <span v-html="getSVG('user')"/>
          <span v-html="displayCreator"/>
        </div>
      </template>
      <template v-if="this.loaded ">
        <h3 class="knowledge-sidebar-header">Letzte Änderung</h3>
        <div class="knowledge-creator-wrapper">
          <span v-html="getSVG('user')"/>
          <span
              v-if="this.entryChanges.length"
              v-html="displayLastChange"
          />
          <span v-else class="knowledge-distance">Keine Änderungen vorgenommen</span>
        </div>
      </template>
    </div>

    <div v-if="this.loaded || !this.data">
    <h4 class="knowledge-sidebar-header">Kategorisierung</h4>
    <form id="manageCategoryForm" v-if="this.loaded || !this.id">
      <div class="knowledge-category-form">
        <FreeSearch placeholder="Überkategorie suchen"
                    name="parentCategory"
                    :filter-function="this.searchCategories"
                    :value="this.data?.relatedTo.id ?? ''"
                    :term="this.data?.relatedTo.title ?? ''"
                    @value-selected="this.categorySelected"
                    class="knowledge-sidebar-category-input "
                    :actions="[
                        {
                          title: 'Ohne Überkategorie',
                          action: saveWithoutCategory
                        }
                    ]"
        />
      </div>
    </form>
    </div>

    <div v-if="this.loaded">
    <h4 class="knowledge-sidebar-header">Zuordnung</h4>
    <form id="manageObjectCustomerForm" >
      <div class="ticket-object-wrapper">
        <FreeSearch placeholder="Objekt/Kunden hinzufügen"
                    name="object&customer"
                    :filter-function="searchObject"
                    :value="(this.data?.id)?this.data.customer.concat(this.data?.object) : ''"
                    @value-selected="objectSelected"
                    ref="object"
        />
      </div>
    </form>
    </div>

    <div v-if="this.loaded"
         class="knowledge-info-mid"
    >
      <div v-if="this.selectedHouses?.length > 0 || this.selectedCustomers?.length > 0">
      <div class="knowledge-entry-list" v-if="this.selectedHouses?.length > 0 || this.selectedCustomers?.length > 0">
        <DynamicTable
            :show-pagination-count="false"
            :count="5"
            v-if="this.selectedHouses.length > 0"
            :buttons="[
                              {
                                  icon: 'delete',
                                  action: this.removeObjectFromWikiEntry
                              }
                          ]"
            :columns="['title']"
            :data="this.selectedHouses"
            :column-names="{
                              title: 'Objekte'
                          }"
            :select-options="[
                          {
                            name:'Löschen',
                            action: this.deleteMultipleObjectsFromWiki,
                          }]"
        />
      </div>
      <div class="knowledge-entry-list">
        <DynamicTable
            :count="5"
            v-if="this.selectedCustomers.length > 0"
            :select-options="[
                                      {
                                        name:'Löschen',
                                        action: this.deleteMultipleCustomersFromWiki,
                                      }]"
            :buttons="[
                                      {
                                          icon: 'delete',
                                          action: this.removeCustomerFromWikiEntry
                                      }
                                    ]"
            :columns="['title']"
            :data="this.selectedCustomers"
            :column-names="{
              title: 'Kunden'
            }"
        />
      </div>
      </div>
      <NoticeArea type="info" v-else>Keine Kunden hinzugefügt</NoticeArea>
    </div>

    <template v-if="this.data?.id">
      <div class="knowledge-history-container">
        <h4 class="knowledge-sidebar-header knowledge-distance-vertical">Historie</h4>
        <div class="history" id="historie" v-html="displayAllChanges"/>
        <SecondaryButton
            class="knowledge-history-button"
            text="weitere änderungen laden"
            @click="this.historyCounter = this.historyCounter + 3"
            v-if="this.entryChanges.length >= 0"
        ></SecondaryButton>
      </div>
    </template>
  </PartedPageStatic>

  <!--
  <PartedPageStatic
      class="knowledge-sidemenu"
      v-if="!this.data"
  >
    <h3 class="knowledge-sidebar-header" >Informationen</h3>
    <div class="knowledge-user-info-sidebar" >
      <template>
        <h3 class="knowledge-sidebar-header">Ersteller</h3>
        <div class="knowledge-creator-wrapper">
          <span v-html="getSVG('user')"/>
          <span v-html="displayCreator"/>
        </div>
      </template>
    </div>

      <h4 class="knowledge-sidebar-header">Kategorisierung</h4>
      <form id="manageCategoryForm" >
        <div class="knowledge-category-form">
          <FreeSearch placeholder="Überkategorie suchen"
                      name="parentCategory"
                      :filter-function="this.searchCategories"
                      :value="this.data?.relatedTo.id ?? ''"
                      :term="this.data?.relatedTo.title ?? ''"
                      @value-selected="this.categorySelected"
                      class="knowledge-sidebar-category-input "
                      :actions="[
                        {
                          title: 'Ohne Überkategorie',
                          action: saveWithoutCategory
                        }
                    ]"
          />
        </div>
      </form>
  </PartedPageStatic>
  -->

  <div class="modal">
    <div class="modal-background"/>
    <div class="modal-content">
      <h3>Kategorie erstellen</h3>
      <form @submit.prevent="createCategory" id="createCategoryForm"
            class="modal-form">
        <TextInput name="title"
                   placeholder="Kategorie Name"
                   class="width"
        />
        <FreeSearch placeholder="Überkategorie suchen"
                    name="parentCategory"
                    :filter-function="searchCategories"
                    :value="this.data?.relatedTo?.id ?? ''"
                    :term="this.data?.relatedTo?.title ?? ''"
                    @value-selected="categorySelected"
        />
      </form>
      <div class="two-buttons">
        <SecondaryButton @click="closeCategoryCreation" text="Schließen"/>
        <PrimaryButton @click="createCategory" text="Speichern"/>
      </div>
    </div>
  </div>
</template>

<script>

import FileUploadTable from "@/components/elements/FileUploadTable.vue";
import PrimaryButton from "@/components/elements/PrimaryButton.vue";
import TextInput from "@/components/elements/TextInput.vue";
import AreaInput from "@/components/elements/AreaInput.vue";
import FreeSearch from "@/components/elements/FreeSearch.vue";
import SelectBox from "@/components/elements/SelectBox.vue";
import CheckBox from "@/components/elements/CheckBox.vue";
import PartedPageDynamic from "@/components/elements/PartedPageDynamic.vue";
import PartedPageStatic from "@/components/elements/PartedPageStatic.vue";
import WYSIWYGEditor from "@/components/elements/WYSIWYGEditor.vue";
import SecondaryButton from "@/components/elements/SecondaryButton.vue";
import DynamicTable from "@/components/elements/DynamicTable.vue";
import ConfirmationOverlay from "@/App.vue";
import FileUploaderWiki from "@/components/elements/FileUploaderWiki.vue";
import FileUploader from "@/components/elements/FileUploader.vue";
import NoticeArea from "@/components/elements/NoticeArea.vue";
import {DateTime} from "luxon";
import DropDownButton from "@/components/elements/DropDownButton.vue";
import NegativeButton from "@/components/elements/NegativeButton.vue";
import {nextTick} from "vue";
import Knowledge from "@/components/static/subMenu/knowledge.vue";
import {parse} from "uuid";


export default {
  data() {
    return {
      id: parseInt(this.$route.params.id) ?? false,
      data: null,
      edit: true,
      currentCategoryTitle: null,
      currentCategoryId: null,
      customers: [],
      description: "",
      accounts: [],
      houses: [],
      files: [],
      entries: [],
      entryChanges: new Array(),
      category: null,
      fromCategory: null,
      categories: [],
      selectedCustomers: [],
      currentCustomers: [],
      selectedHouses: [],
      currentPage: isNaN(parseInt(this.$route.query.page)) ? 1 : parseInt(this.$route.query.page),
      count: 20,
      interruptedObject: [],
      interruptedCustomer: [],
      loaded: false,
      loadedCustomers: false,
      loadedObjects: false,
      historyCounter: 3,
      content: "",
      noCategory: false


    }
  },
  props: {},
  components: {
    FileUploadTable,
    Knowledge,
    NegativeButton,
    DropDownButton,
    FileUploader,
    ConfirmationOverlay,
    SecondaryButton,
    WYSIWYGEditor,
    PrimaryButton,
    TextInput,
    SelectBox,
    PartedPageDynamic,
    PartedPageStatic,
    FreeSearch,
    AreaInput,
    CheckBox,
    FileUploaderWiki,
    DynamicTable,
    NoticeArea
  },
  watch: {
    $route(to, ignored_from) {
      if(this.data === null || this.data === undefined) {
        return;
      }

      this.id = to.params.id ?? false;
      if (this.data?.id) {
        this.edit = false;
        this.data.relatedTo.id = null;
        this.data.relatedTo.title = null;

      } else {
        this.data = null;
        this.edit = true;
        this.fromCategory = ignored_from.query.categoryId;
      }
      this.data = null;
      this.selectedHouses = [];
      this.selectedCustomers = [];
      this.loaded = false;
      this.historyCounter = 0;
      this.clicks = {};
      this.bufferWaitTime();
    }
  },
  methods: {
    async bufferWaitTime() {
      await nextTick();
      await nextTick();
      await nextTick();
      await nextTick();
      await nextTick();
      await nextTick();
      await this.loadEntry();
      await this.loadConnectedCustomer();
      await this.loadConnectedObjects();

    },
    async loadData() {
      const acc = await this.apiRequest("GET", "/accounts");
      this.accounts = [];
      for (const account of acc) {
        this.accounts.push({
          id: account.id,
          title: account.firstName + " " + account.lastName
        });
      }
    },

    async loadCategories() {
      const cnt = await this.apiRequest("GET", "/category/");
      this.categories = cnt;

      this.fromCategory = this.$route.query.categoryId;
      for (let item of this.categories) {
        if (item.id === parseInt(this.fromCategory)) {
          this.fromCategory = {
            id: item.id,
            title: item.title
          }
        }
      }
      if (this.fromCategory != null) {
        document.querySelector("input[name='parentCategory_textField']").value = this.fromCategory.title;
      }

    },
    async loadEntry() {
      if (this.id) {

        this.data = await this.apiRequest("GET", `/wiki/${this.id}`);
        let category = this.categories.find(c => c.id === this.data.parentCategory);

        this.data.relatedTo = {
          id: category?.id ?? '',
          title: category?.title ?? ''
        };

        this.category = this.data.relatedTo.id;
        this.currentCategoryTitle = this.data.relatedTo.title;
        this.currentCategoryId = this.data.relatedTo.id;
        this.entryChanges = await this.apiRequest("GET", "/wiki/history/" + this.id);
        this.data.lastCategory = this.categories.filter(item => item.id === this.data?.relatedTo.id).map(i => i.id);

        if(this.data.parentCategory !== 0) {
          this.setBreadCrumbs([{
            title: 'Wissen',
            link: `/knowledge/${this.data.lastCategory}`
          }]);
        }
      }
      this.loaded = true;
    },

    closeCategoryCreation() {
      document.querySelector(".modal").classList.remove("visible");
    },
    objectNotEmpty() {
      let objectInput = document.querySelector("input[name='object_textField']");
      return objectInput !== '';
    },
    openCategoryCreation() {
      (document.querySelector(".modal").classList.contains("visible")) ? document.querySelector(".modal").classList.remove("visible") : document.querySelector(".modal").classList.add("visible");
    },
    async createCategory() {
      document.querySelector(".modal").classList.remove("visible");
      let formData = this.getFormData("createCategoryForm");
      formData = {
        title: formData.title,
        parent: (formData.parentCategory === '') ? -1 : parseInt(formData.parentCategory)
      }

      let categoryId = formData.id ?? 0;

      const res = await this.apiRequest(categoryId ? "PUT" : "POST", "/wiki/category/", formData);
      if (res.success) {
        this.showNotice({
          type: "success",
          content: "Kategorie erfolgreich " + (this.data?.id ? "gespeichert" : "erstellt") + "!",
        });
        await this.apiRequest("GET", "/category")
            .then(res => {
              for (let cat of res) {
                if (cat.title === formData.title) {
                  this.$router.push({path: '/knowledge/' + cat.id});
                }
              }
            });
      } else {
        this.showAPIError(res, "Fehler beim Erstellen der Kategorie!");
      }
    },
    async pushEntry(notEdit = false) {

      const formData = this.getFormData("manageEntryForm");
      const categoryData = this.getFormData("manageCategoryForm");


      if (formData.title === "" && formData.content === undefined) {

        this.showNotice({
          type: "warning",
          content: "Beitrag kann Leer nicht gespeichert werden!",
        });
        return;
      }


      (notEdit) ? this.edit = !this.edit : null;


      let objects = [], customers = [];

      for (let item of this.selectedCustomers) {
        customers.push(item.id)
      }

      for (let item of this.selectedHouses) {
        objects.push(item.id)
      }


      (objects.length < 1) ? objects = [] : objects;
      (customers.length < 1) ? customers = [] : customers;

      formData.creationDate = new Date();


      if (categoryData.parentCategory_textField !== '' ) {
        formData.parentCategory = this.categories.filter(item => item.title === categoryData.parentCategory_textField).map(item => item.id);
      }else {
        formData.parentCategory = 0;
      }


      if (this.noCategory) {
        formData.parentCategory = 0;
      }

      if (formData.parentCategory === undefined) formData.parentCategory = 0;

      if (formData.parentCatgory_textField !== '' && this.fromCategory !== null) {
        formData.relatedTo = this.fromCategory;
      } else {
        formData.relatedTo = {
          id: this.data?.parentCategory,
          title: this.categories[0]?.title
        };
      }

      if(isNaN(formData.parentCategory) && (formData.relatedTo.id === undefined) && (formData.relatedTo.title)) {
        formData.parentCategory = 0;
        formData.relatedTo =  {
          id: 0,
          title: "Ohne Kategorie"
        }
      }

      formData.creator = this.$store.state?.user?.firstName + " " + this.$store.state?.user?.lastName;
      formData.changedBy = (formData.creator && this.$store.state?.user?.firstName + " " + this.$store.state?.user?.lastName !== formData.creator) ? this.this.$store.state?.user?.firstName + " " + this.$store.state?.user?.lastName : formData.creator;
      (this.data?.id) ? formData.content = this.data?.content : formData.content = this.content;

      formData.object = objects;
      formData.customer = customers;

      console.log(formData);


      const res = await this.apiRequest(this.id ? "PUT" : "POST", "/wiki/" + (this.id ? this.id : ''), formData);
      if (res.success) {
        const entryId = res.id ?? 0;
        (this.id) ? await this.$refs.wikiFileTable.uploadTempFiles("wiki", this.id) : ("");
        this.showNotice({
          type: "success",
          content: "Beitrag erfolgreich " + (this.data?.id ? "gespeichert" : "hinzugefügt") + "!",
        });
        await this.loadStuff();
        this.emitter.emit('new-entry');
        this.reloadMenu();
        (formData.title && this.data) ? this.data.title = formData.title : "";

        document.querySelector("#knowledge-header").innerText = (formData.title) ? formData.title : this.data.title;
        document.querySelector("#knowledge-content").innerHTML = formData.content;

        //this.$router.push({path: "/knowledge/manage/"+this.id});

      } else {
        this.showAPIError(res, "Fehler beim Speichern des Beitrags!");
      }

    },
    async saveWithoutCategory() {
      this.noCategory = true;
      this.data.parentCategory = "";
      this.data.relatedTo.id = "";
      this.data.relatedTo.title = "";
      console.log(this.categories);
      this.data.lastCategory = this.categories[0].id;

      document.querySelector("input[name='parentCategory_textField']").value = "";
      document.querySelector("input[name='parentCategory']").value = "";

      await this.pushEntry();
    },
    saveCategory(category) {
      if (!this.data?.id) return;
      else {
        this.categories.forEach(async (value, key) => {
          if (value.title === category.title) {
            this.data.relatedTo = {
              id: category.id,
              title: category.title
            }
            this.data.parentCategory = category.id;
            let res = await this.apiRequest("PUT", "/wiki/" + this.data?.id, this.data.parentCategory);
            if (res.success) {
              this.showNotice({
                type: "success",
                content: "Beitrag erfolgreich " + (this.data?.id ? "gespeichert" : "hinzugefügt") + "!",
              });
            } else {
              this.showAPIError(res, "Fehler beim Speichern des Beitrags!");
            }
          }
        });
      }
    },
    async deleteWiki() {
      let category = this.data.relatedTo.id;
      if (await this.confirm("Möchten Sie den Beitrag &quot;"
          + this.data.title
          + "&quot; wirklich löschen?")) {
        let res = await this.apiRequest("DELETE", "/wiki/" + this.data?.id)
        if (res.success) {
          await this.loadStuff();
          this.emitter.emit('remove-entry');
          this.reloadMenu();
        }

        this.$router.push({path: '/knowledge/' + category})
      }
    },
    customerSelected(term) {
      if (this.selectedCustomers.filter(item => item === term.id)) {
        if (this.confirm("Dieses Objekt ist schon mit dem Aktuellen Beitrag verknüpft.")) {
          document.querySelector("input[name='object&customer_textField']").value = "";
        }
        return;
      } else {
        this.selectedCustomers.push({
          id: term.id,
          title: term.title + " (" + term.customerNumber + ")"
        });
      }
    },
    async searchCategories(term) {
      if (term === undefined) return;
      term = term.trim();
      if (this.categories.filter(res => res.title.toLowerCase().includes(term.toLowerCase())).length <= 4) {
        document.querySelector("input[name='parentCategory_textField']").classList.remove("hideCategoryScrollbar");
      }
      let noCategeory = "<";
      let filteredCategories = this.categories.filter(res => res.title.toLowerCase().includes(term.toLowerCase()));
      return filteredCategories
    },
    async categorySelected(val) {

      if (this.data === null) {
        this.data = {};
      }


      this.data.relatedTo = this.getItemById(val.id, this.categories);
      this.data.relatedTo = {
        id: val.id,
        title: val.title
      };

      this.data.parentCategory = val.id;
      await this.pushEntry();
    },
    async searchCustomer(term) {
      term = term.trim();
      return this.currentCustomers.filter(customer => (term === '' || customer.title.toLowerCase().includes(term.toLowerCase())));
    },
    async searchObject(term) {
      if (term.length < 3) {
        return [];
      }
      term = term.trim();

      let filteredCustomers = [];
      let filteredHouses = this.houses?.filter(res => res.title.toLowerCase().includes(term.toLowerCase()));
      let filteredCustomersTMP = this.customers?.filter(item => (item.firstName === null) ? item.lastName.toLowerCase().includes(term.toLowerCase()) : ((item.firstName ?? '') + ' ' + (item.lastName ?? '')).toLowerCase().includes(term.toLowerCase()))
      filteredCustomersTMP.forEach(item => {
        filteredCustomers.push({
          id: item.id,
          title: (item.firstName === null) ? item.lastName : item.firstName + " "  + item.lastName
        });
      });


      return filteredCustomers.concat(filteredHouses);
    },
    async objectSelected(val) {
      if (this.selectedHouses.length === 1) {
        if (val.id === this.selectedHouses[0].id) {
          if (this.confirm("Dieses Objekt ist schon mit dem Aktuellen Beitrag verknüpft.")) {
            document.querySelector("input[name='object&customer_textField']").value = "";
          }
          return;
        }
      } else {
        let isFound = false;
        this.selectedHouses.forEach(item => {
          if (val.id === item.id) {
            if (this.confirm("Dieses Objekt ist schon mit dem Aktuellen Beitrag verknüpft.")) {
              document.querySelector("input[name='object&customer_textField']").value = "";
            }
            isFound = true;

            return;
          }
        });
        if (isFound) return;
      }

      if (this.selectedCustomers.length === 1) {
        if (val.id === this.selectedCustomers[0].id) {
          if (this.confirm("Dieser Kunde ist schon mit dem Aktuellen Beitrag verknüpft.")) {
            document.querySelector("input[name='object&customer_textField']").value = "";
          }

          return;
        }
      } else {
        let isFound = false;
        this.selectedCustomers.forEach(item => {
          console.log(item.id)
          console.log(val.id)

          if (item.id === val.id) {
            if (this.confirm("Dieser Kunde ist schon mit dem Aktuellen Beitrag verknüpft.")) {
              document.querySelector("input[name='object&customer_textField']").value = "";
            }
            isFound = true;

            return;
          }
        })
        if (isFound) return;
      }

      if (val.id > 999999) {
        nextTick().then(() => {
          document.querySelector("input[name='object&customer_textField']").value = "";
        });
        this.selectedHouses.push(val);
      } else {
        nextTick().then(() => {
          document.querySelector("input[name='object&customer_textField']").value = "";
        });
        this.selectedCustomers.push({
          id: val.id,
          title: val.title + " (" + val.id + ")"
        });

      }

      if (this.id) {
        this.pushEntry();
      }

    },
    async removeObjectFromWikiEntry(id) {
      if (Array.isArray(id)) {
        if (await this.confirm("Möchten Sie diese Objekte wirklich löschen?")) {
          for (let number of id) {
            this.selectedHouses = this.selectedHouses.filter(item => item.id !== parseInt(number));
            this.data.object = this.selectedHouses.map(item => item.id);
          }

          this.data.changedBy = this.$store.state?.user?.firstName + " " + this.$store.state?.user?.lastName;

          let res = await this.apiRequest("PUT", "/wiki/" + this.data?.id, this.data);
          if (res.success) {
            this.showNotice({
              type: "success",
              content: "Objekte erfolgreich entfernt!",
            });
          } else {
            this.showAPIError(res, "Fehler beim Löschen der Objekte!");
          }
        }
      } else {
        if (await this.confirm("Möchten Sie das Objekt &quot;"
            + this.selectedHouses.filter(item => item.id === parseInt(id)).map(item => item.title)
            + "&quot; wirklich löschen?")) {

          this.data.object = this.data.object.filter(item => item !== id);
          this.selectedHouses = this.selectedHouses.filter(item => item.id !== id);
          this.data.changedBy = this.$store.state?.user?.firstName + " " + this.$store.state?.user?.lastName;


          let res = await this.apiRequest("PUT", "/wiki/" + this.data?.id, this.data);
          if (res.success) {
            this.showNotice({
              type: "success",
              content: "Objekt erfolgreich entfernt!",
            });
          } else {
            this.showAPIError(res, "Fehler beim Löschen des Objektes!");
          }
        }

      }
    },
    async deleteMultipleObjectsFromWiki(ids) {
      if(Array.isArray(ids) && !ids.length) {
        this.showNotice({
          type: "warning",
          content: "Keine Objekte ausgewählt!",
        });
        return;
      }
      await this.removeObjectFromWikiEntry(ids);
    },
    async removeCustomerFromWikiEntry(evnt) {
      console.log(evnt);
      if (this.selectedCustomers.length === 1) {
        if (evnt.id === this.selectedCustomers[0].id) {
          document.querySelector("input[name='object&customer_textField']").value = "";
          return;
        }
      } else {
        this.selectedCustomers.forEach(item => {
          if (item === evnt.id) {
            document.querySelector("input[name='object&customer_textField']").value = "";
            return;
          }
        })
      }
      if (Array.isArray(evnt)) {
        if (await this.confirm("Möchten Sie diese Kunden wirklich löschen?")) {
          for (let number of evnt) {
            this.selectedCustomers = this.selectedCustomers.filter(item => item.id !== parseInt(number));
            this.data.customer = this.selectedCustomers.map(item => item.id);
          }
        }
        this.data.changedBy = this.$store.state?.user?.firstName + " " + this.$store.state?.user?.lastName;


        let res = await this.apiRequest("PUT", "/wiki/" + this.data?.id, this.data);
        if (res.success) {
          this.showNotice({
            type: "success",
            content: "Kunden erfolgreich entfernt!",
          });
          this.selectedCustomers = this.selectedCustomers.filter(item => item !== evnt);

        } else {
          this.showAPIError(res, "Fehler beim Löschen der Kunden!");
        }
      } else {
        if (await this.confirm("Möchten Sie den Kunden &quot;"
            + this.selectedCustomers.filter(item => item.id === parseInt(evnt)).map(item => item.title)
            + "&quot; wirklich löschen?")) {

          let tmpCustomers = this.selectedCustomers;
          this.data.customer = [];
          for (let item of tmpCustomers) {
            if (item.id !== evnt) {
              this.data.customer.push(item.id)
            }
          }

          this.data.changedBy = this.$store.state?.user?.firstName + " " + this.$store.state?.user?.lastName;

        }
        let res = await this.apiRequest("PUT", "/wiki/" + this.data?.id, this.data);
        if (res.success) {
          this.showNotice({
            type: "success",
            content: "Kunde erfolgreich entfernt!",
          });
          this.selectedCustomers = this.selectedCustomers.filter(item => item.id !== evnt)

        } else {
          this.showAPIError(res, "Fehler beim Löschen des Kunden!");
        }
      }
    },
    async deleteMultipleCustomersFromWiki(ids) {
      if(Array.isArray(ids) && !ids.length) {
        this.showNotice({
          type: "warning",
          content: "Keine Kunden ausgewählt!",
        });
        return;
      }
      await this.removeCustomerFromWikiEntry(ids);
    },
    async updateContent(text) {
      if (this.id) {
        text = text.toLowerCase().replaceAll("&lt;script&gt;", "");
        text = text.toLowerCase().replaceAll("&lt;/script&gt;", "");
        this.data.content = text;
      } else {
        this.content = text;
      }
    },
    async loadConnectedObjects() {
      if (!this?.id) return;

      let houseNumber = this.data?.object;
      if (typeof houseNumber === 'object') {
        for (let houseNR of houseNumber) {
          this.houses.forEach(item => {
            if (item.id === houseNR) {
              this.selectedHouses.push(item);
            }
          });
        }
      } else {
        this.houses.forEach(item => {
          if (item.id === houseNumber) {
            this.selectedHouses.push(item);
          }
        });
      };
      this.loadedObjects = true;
    },
    async loadConnectedCustomer() {
      if (!this?.id) return;
      let customerNumber = this.data?.customer;
      if (!customerNumber) return;
      if (typeof customerNumber === 'object') {
        for (let customerNR of customerNumber) {
          this.customers.forEach(item => {
            if (item.id === customerNR) {
              this.selectedCustomers.push({
                id: item.id,
                title: (item.firstName ? item.firstName : '') + " " + item.lastName + " (" + item.id + ") "
              });
            }
          });
        }
      } else {
        this.customers.forEach(item => {
              if (item.id === customerNumber) {
                this.selectedCustomers.push({
                  id: item.id,
                  title: (item.firstName ? item.firstName : '') + " " + item.lastName + " (" + item.id + ") "
                });
              }
            },
        );
      }
      this.loadedCustomers = true;
    },
    getTimeText(timestamp) {
      let text = '';
      const diff = DateTime.fromJSDate(timestamp).diff(DateTime.now(), 'minutes');
      const diffMinutes = Math.ceil(Math.abs(diff.as('minutes')));
      const diffHours = Math.floor(Math.abs(diff.as('hours')));
      const diffMonths = Math.floor(Math.abs(diff.as('months')));
      const diffWeeks = Math.floor(Math.abs(diff.as('weeks')));
      const diffDays = Math.floor(Math.abs(diff.as('days')));
      const diffYears = Math.floor(Math.abs(diff.as('years')));

      if (diffHours < 1) {
        text = "vor " + diffMinutes + " " + (diffMinutes === 1 ? 'Minute' : 'Minuten');
      } else if (diffHours < 24) {
        text = "vor " + diffHours + " " + (diffHours === 1 ? 'Stunde' : 'Stunden');
      } else {
        text = DateTime.fromJSDate(new Date(timestamp)).toFormat("dd.MM.yyyy HH:mm");
      }
      return text;
    },

  },
  computed: {
    displayCreator() {
      if (this.data?.id) {
        let changedDate = DateTime.fromJSDate(new Date(this.data.creationDate)).toFormat("dd.MM.yyyy HH:mm")
        return "<span class='knowledge-creator-text'><b>" + this.data.creator + "</b> am " + changedDate + "</span>";
      } else {
        return "<span class='knowledge-creator-text'><b>" + this.$store.state?.user?.firstName + " " + this.$store.state?.user?.lastName + "</b> am " + DateTime.fromJSDate(new Date(Date.now())).toFormat("dd.MM.yyyy HH:mm") + "</span>";
      }
    },
    displayLastChange() {
      let lastChange = this?.entryChanges.pop();
      if (lastChange.changed_field === "created") return "<span class='knowledge-creator-text'>Keine Änderungen vorhanden</span>";

      let changedDate = DateTime.fromJSDate(new Date(lastChange.date_changed)).toFormat("dd.MM.yyyy HH:mm");
      this?.entryChanges.push(lastChange)

      return "<span class='knowledge-creator-text'><b>" + lastChange.changed_by + "</b> am " + changedDate + "</span>";
    },
    displayAllChanges() {
      let output = "";

      if (this.data.id) {
        if (this.historyCounter === 3 ) {
          console.log(this.entryChanges);
          this.entryChanges.splice(0, 0, {
            changed_by: this.data.creator,
            changed_field: "created",
            date_changed: this.data.creationDate,
            entryid: this.data.id,
            value_after: "",
            value_before: ""
          });
        }else if (this.entryChanges.length === this.historyCounter) {
          return;
        }

        console.log(this.historyCounter);


        let changes = [...this.entryChanges];
        changes = changes.reverse();

        let counter = 0;
        for (let change of changes) {
          if (counter === this.historyCounter) break;
          if (change !== null || change !== "") {
            if (change.changed_field !== "creator") {
              output += "<div class='history-item'><div class='knowledge-history-header'><div class='knowledge-history-image'></div><b>" + change.changed_by + "</b>" + this.getTimeText(new Date(change.date_changed)) + "</div>";
              switch (change.changed_field) {
                case "created":
                  output += "<div class='knowledge-history-text'><b>Artikel erstellt </b>" + change.value_after + "</div>";
                  break;
                case "entry":
                  output += "<div class='knowledge-history-text'><b>Beitrag hinzugefügt:</b> " + change.value_after + "</div>";
                  break;
                case "title":
                  output += "<div class='knowledge-history-text'> <b>Titel geändert </b><br></div>";
                  output += "<div class='knowledge-history-text'>" + change.value_after + "</div>";
                  break;
                case "content":
                  output += "<div class='knowledge-history-text'> <b>Beitragstext geändert </b></div>";
                  break;
                case "object":
                  let objects = [];
                  for (let object of (change.value_after !== null) ? change.value_after.split(",") : ["Objekte entfernt"]) {
                    if(object === ["Objekte entfernt"]){
                      objects = object;
                    }else {
                      objects = objects.concat(this.houses.filter(item => item.id === parseInt(object)).map(item => item.title));
                    }
                  }

                  output += "<div class='knowledge-history-text'>";
                  output += " <b>Objekt geändert </b><br>";
                  output += "" + objects.length > 1 ? objects.join(", ") + "" : "";
                  output += "</div>";
                  break;
                case "customer":
                  let customers = [];
                  for (let customer of (change.value_after !== null) ? change.value_after.split(",") : ["Kunden entfernt"]) {
                    if(customer === ["Kunden entfernt"]){
                      customers = customer;
                    }else {
                      customers = customers.concat(this.customers.filter(item => item.id === parseInt(customer)).map(item => item.firstName + " " + item.lastName));
                    }
                  }
                  output += "<div class='knowledge-history-text'>";
                  output += " <b>Kunde geändert </b><br> ";
                  output += this.data.customer.length > 0 ? customers.join(", ") : "";
                  output += "</div>";
                  break;
                case "parentCategory":
                  let categoryTitle = [];
                  let categoryTitleBefore = (parseInt(change.value_before) !== 0 || change.value_after != "_") ? this.categories.filter(item => item.id === parseInt(change.value_before)).map(item => item.title) : "Ohne Kategorie";
                  for (let cat of this.categories) {
                    if (cat.id === parseInt(change.value_after)) {
                      categoryTitle = this.categories.filter(item => item.id === parseInt(change.value_after)).map(it => it.title)[0];

                      if ((Array.isArray(categoryTitle) && !categoryTitle.length)) {
                        categoryTitle = "Ohne Kategorie";
                      }
                    }
                  }
                  if (categoryTitle === undefined) {
                    categoryTitle = "Ohne Kategorie";
                  }

                  if ((Array.isArray(categoryTitle) && !categoryTitle.length)) {
                    categoryTitle = "Ohne Kategorie";
                  }

                  if ((Array.isArray(categoryTitleBefore) && !categoryTitleBefore.length)) {
                    categoryTitleBefore = "Ohne Kategorie";
                  }


                  output += "<div class='knowledge-history-text'>";
                  output += " <b>Artikelzuordnung geändert </b><br>";
                  output += "" + categoryTitleBefore + "<span class='knowledge-category-switch-arrow'>" + this.getSVG('arrow_right') + "</span> " + categoryTitle + "";
                  output += "</div>";
                  break;
                default:
                  console.log(change.changed_field);
                  output += "default: " + change.value_after;
                  break;

              }
              output += "</div>";
            }
            counter = counter + 1;
          }
        }
        console.log(this.historyCounter);
      }

      return output += "";
    }
  },



  async mounted() {

  },
  async created() {
    this.id = this.$route.params.id ?? '';
    this.$store.commit("setPageTitle", "Knowledge");

    await this.loadData();

    this.houses = await this.apiRequest("GET", "/houses/search");
    this.customers = await this.apiRequest("GET", "/customers");

    await this.loadCategories();

    if (this.id) {
      this.edit = false;
      this.data = null;
      await this.loadEntry();
    }

    await this.loadConnectedCustomer();
    await this.loadConnectedObjects();

    if(this.data?.id) {
      if(this.categories?.length > 0 && this.loaded)
        this.setBreadCrumbs([{
          title: 'Wissen',
          link: `/knowledge/${this.data.lastCategory}`
        }]);
    }else {
      console.log(this.$route.query.categoryId);
      this.setBreadCrumbs([{
        title: 'Wissen',
        link: `/knowledge/${this.$route.query.categoryId}`
      }]);
    }
  }
}
</script>