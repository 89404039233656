<template>
  <div :id="'filter-search-bar-container-'+this.id" class="filter-search-bar-container">
    <div class="table-filters-container" v-if="Array.isArray(filters)||Array.isArray(selectOptions)">
      <div class="table-filters ticket">
        <template v-if="Array.isArray(filters)">
          <template v-for="filter in filters">
            <div class="table-filter" @click="toggleFilter">
              <div class="table-filter-name">{{ filter.name }} <span v-html="getSVG('caret_down')" />
              </div>
              <div class="table-filter-options custom-select-box-list ticket">
                <div class="table-filter-option custom-select-box-option"
                     v-for="option in filter.values"
                     @click="() => addFilter(filter,option)">
                  {{ option.title }}
                </div>
              </div>
            </div>
          </template>
        </template>
        <div class="table-filter sort" v-if="Array.isArray(sortOptionsFinal)">
          <div @click.self="toggleFilter" class="table-filter-name">Sortiert nach <span @click.self="toggleFilter"
                                                                                        class="sort-preview-text">{{
              getSortPreviewText
            }}</span><span
              v-html="getSVG('caret_down')" /></div>
          <div class="table-filter-options custom-select-box-list sort-option-list">
            <Sortable
                :list="sortOptionsFinal"
                item-key="id"
                @update="updateSorted"
                :options="{
                                animation: 150,
                                handle: '.sort-option-dots',
                                ghostClass: 'ghost-hidden'
                            }">
              <template #item="{element, index}">
                <div :data-id="element.id"
                     class="prevent-closing table-filter-option custom-select-box-option sort-option">
                  <span class="sort-option-dots" v-html="getSVG('sort_dots')" />
                  <CheckBox
                      :inline="true"
                      @change="updateSorted"
                  />
                  <div class="sort-option-name">{{ element.name }}</div>
                  <span :id="'direction-'+element.id" :class="'sort-option-direction'"
                        @click="toggleDirection(element)"
                        v-html="getSVG('desc')" />
                </div>
              </template>
            </Sortable>

            <div class="sort-actions prevent-closing">
              <div class="sort-action-link secondary" @click="resetSorted">Reset</div>
              <div :class="'sort-action-link' +  (this.sortChanged? '' : ' disabled')" @click="submitSorted">
                Anwenden
              </div>
            </div>
          </div>

        </div>

        <div class="table-filter" v-if="Array.isArray(selectOptions)" @click="(event) => toggleFilter(event)">
          <div class="table-filter-name">Ausgewählte <span v-html="getSVG('caret_down')" /></div>
          <div class="table-filter-options custom-select-box-list">
            <div class="table-filter-option custom-select-box-option"
                 v-for="option in selectOptions"
                 @click="() => executeMultiAction(option)"
            >{{ option.name }}
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="input-freesearch-wrapper" v-if="this.showSearchBar === undefined || (this.showSearchBar !== null && this.showSearchBar)">
      <input placeholder="Filtern/Suchen..." type="text" @keydown="(event) => {
                  if (event.target.value === '') {
                    if (event.keyCode === 8) {
                        if(this.filtered.length > 0)
                            this.filtered.length -= 1;
                        this.update();
                    }
                  }
              }"
             @keyup="onChangeInput"
             class="input-text input input-freesearch filter-search-bar-input">
      <div class="filter-cards">
        <div class="filter-card" v-for="filter in this.filtered">
          {{ filter.name }}: {{ filter.valueName }}
          <span @click="() => removeFilter(filter)" v-html="getSVG('times')" />
        </div>
      </div>
      <span class="filter-search-bar-clear" @click="clear" v-if="this.filtered.length > 0">Leeren</span>
      <div class="freesearch-results filter" tabindex="0">
        <div v-for="val in this.filterResults" :data-filter-result-id="val.field ?? val.id"
             :data-filter-result-name="val.name ?? val.title" class="freesearch-result" @click="clickResult"
             tabindex="0">{{ val.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {v4 as uuid4} from "uuid";
import WYSIWYGEditor from "@/components/elements/WYSIWYGEditor.vue";
import CheckBox from "@/components/elements/CheckBox.vue";
import {nextTick} from "vue";
import {Sortable} from "sortablejs-vue3";


export default {
  name: "FilterSearchBar",
  components: {WYSIWYGEditor, Sortable, CheckBox},
  props: [
    "filters",
    "actions",
    "selectOptions",
    "filteredAddition",
    "sortOptions",
    "showSearchBar",
      "table"
  ],
  data() {
    return {
      id: null,
      filtered: [],
      sorted: [],
      currentSorted: [],
      filterResults: [],
      sortChanged: false,
      sortOptionsFinal: undefined,
      initialSortOptions: null
    }
  },
  watch: {
    filteredAddition(to, from) {
      this.updateFilteredAddition();
    }
  },
  created() {
    this.id = uuid4();
  },
  mounted() {
    this.updateFilterResults();
    this.initialSortOptions = this.sortOptions;
    this.sortOptionsFinal = this.sortOptions;
  },
  computed: {
    getSortPreviewText() {
      const length = this.currentSorted.length;
      if(!this.currentSorted || length === 0) return "";
      let text = " " + this.currentSorted[0].name;
      if(length > 1) {
        text = text + " +" + (length - 1);
      }
      return text;
    }
  },
  methods: {
    resetSorted() {
      this.sortOptionsFinal = [];
      nextTick().then(() => {
        const container = document.getElementById('filter-search-bar-container-' + this.id);
        this.sortOptionsFinal = this.initialSortOptions;
        this.updateSorted();
        this.submitSorted(container.querySelector(".table-filter.sort"));
      })
    },
    submitSorted(event) {
      let target = event.target ?? event;
      if(target.classList.contains('disabled')) return;
      this.$emit("sortedChanged", this.sorted);
      this.currentSorted = this.sorted;
      this.sortChanged = false;
      this.toggleFilter(event);
    },
    updateSorted() {
      this.sorted = [];
      for(let sortOption of document.querySelectorAll(".sort-option")) {
        let checkbox = sortOption.querySelector("input[type=checkbox]");
        if(!checkbox.checked) continue;
        let id = sortOption.dataset.id;
        let option = this.sortOptions.find(so => so.id === id);
        this.sorted.push(option);
      }

      this.sortChanged = !(this.sorted.length === 0 && this.currentSorted.length === 0);
    },
    toggleDirection(option) {
      option.desc = !option.desc;
      let el = document.getElementById("direction-" + option.id);
      el.classList.toggle("asc", !option.desc);
      this.updateSorted();
    },
    updateFilteredAddition() {
      for(let filteredElement of this.filtered) {
        if(filteredElement.addition) {
          this.removeFilter(filteredElement);
        }
      }
      for(let filteredAdditionElement of this.filteredAddition) {
        filteredAdditionElement.addition = true;
        this.filtered.push(filteredAdditionElement);
      }
      nextTick().then(() => this.update());
    },
    executeMultiAction(option) {
      if(this.table) {
        this.table.executeMultiAction(option);
      } else {
        option.action();
      }
    },
    clear() {
      this.filtered = [];
      this.update();
    },
    makeInputToCard() {

    },
    clickResult(event) {
      const resultName = event.target.dataset.filterResultName;
      const resultId = event.target.dataset.filterResultId;
      const container = document.getElementById('filter-search-bar-container-' + this.id);
      const input = container.querySelector(".filter-search-bar-input");
      const currentFilter = this.getCurrentFilter();
      if(currentFilter === null || currentFilter === undefined) {
        input.value = resultName + ": ";
        input.focus();
      } else {
        input.value = "";
        this.filtered.push({
          name: currentFilter.name,
          field: currentFilter.field,
          valueName: resultName,
          value: resultId
        });
        this.update();
        input.focus();
        input.blur();
      }
      this.updateFilterResults();
    },
    updateFilterResults() {
      if(!this.filters) return;
      const value = this.getValue().trim();
      const filters = this.filters.map(a => {
        return {...a, title: a.name, id: a.field};
      });

      let results = [];
      const currentFilter = this.getCurrentFilter();
      if(currentFilter === null || currentFilter === undefined) {
        this.filterResults = filters.filter(filter => filter.name.toLowerCase().includes(value.toLowerCase()));
      } else {
        this.filterResults = currentFilter.values;
      }
    },
    getCurrentFilter() {
      const value = this.getValue().trim();
      const filter = this.filters.find(filter => value.toLowerCase().startsWith(filter.name.toLowerCase() + ":"))
      return filter;
    },
    onChangeInput(event) {
      this.updateFilterResults();

    },
    getValue() {
      const container = document.getElementById('filter-search-bar-container-' + this.id);
      if(container === null) return '';
      const input = container.querySelector(".filter-search-bar-input");
      if(input === null) return '';
      return input.value;
    },
    update() {
      this.$emit('filtersChanged', this.filtered)
      nextTick().then(() => {
        const container = document.getElementById('filter-search-bar-container-' + this.id);
        container.style.setProperty('--text-indent', this.getTextIndentAndPadding()[0] + "px");
        container.style.setProperty('--padding-top', (this.getTextIndentAndPadding()[1]) + "px");
        container.style.setProperty('--padding-bottom', (this.getTextIndentAndPadding()[2]) + "px");
      });
    },
    getInputWidth() {
      const container = document.getElementById('filter-search-bar-container-' + this.id);
      const input = container.querySelector(".filter-search-bar-input");
      return input.offsetWidth - 70;
    },
    getTextIndentAndPadding() {
      let indent = 5;
      let padding = 5;
      let paddingBottom = 30;
      const cards = document.querySelectorAll(".filter-card");
      for(let card of cards) {
        if(indent + card.offsetWidth + 10 > this.getInputWidth()) {
          indent = 0;
          padding += 50;
          paddingBottom += 10;
        }
        indent += card.offsetWidth + 10;
      }
      return [indent, padding, paddingBottom];
    },
    addFilter(filter, option) {
      const current = this.filtered.find(item => item.value === option.id);
      if(current === undefined) {
        this.filtered.push({name: filter.name, field: filter.field, valueName: option.title, value: option.id});
      } else {
        this.filtered = this.filtered.filter(item => item.value !== option.id);
      }
      this.update();
    },
    removeFilter(filter) {
      this.filtered = this.filtered.filter(item => item !== filter);
      this.update();
    },
    toggleFilter(event) {
      let target = event.target ?? event;
      if(target) {
        target = target.closest('.table-filter').querySelector(".custom-select-box-list");
        for(let filterElement of document.querySelectorAll(".table-filter")) {
          let next = filterElement.querySelector(".custom-select-box-list");
          if(next !== target)
            next.classList.remove("open");
        }
        target.classList.toggle("open");
      }
    },


  }
}
</script>
