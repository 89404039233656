<template>
  <div class="drop-down-button-container input-freesearch-wrapper">
    <button @click="toggleFreesearchResult" class="button button-primary">{{ text}} <span class="svg-nonclick" v-html="getSVG('caret_down')"/></button>
    <div class="drop-down-button-actions freesearch-results" tabindex="0"  v-show="showFreesearchResult">
      <template v-if="Array.isArray(this.actions)">
        <div class="freesearch-result drop-down-button-action freesearch-result" v-for="action in actions" @click="action.action">{{ action.title }}</div>
      </template>
    </div>
  </div>
</template>
<script>
export default {

  props: [
    "text",
    "style",
    "actions"
  ],
  data() {
      return {
          showFreesearchResult: false
      }
  },
    methods:{
      toggleFreesearchResult() {
          this.showFreesearchResult = !this.showFreesearchResult;
      }
    }
}
</script>