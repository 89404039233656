const route = {
    path: '/netplan',
    children: [
        {
            path: '',
            components: {
                default:
                    () => import(/* webpackChunkName: "houses" */ '../../components/views/netplan.vue'),
                Sidebar:
                    () => import(/* webpackChunkName: "houses" */ '../../components/static/subMenu/netplan.vue'),
            }
        },
        {
            path: 'devices',
            components: {
                default:
                    () => import(/* webpackChunkName: "house" */ '../../components/views/netplan/devices.vue'),
                Sidebar:
                    () => import(/* webpackChunkName: "houses" */ '../../components/static/subMenu/netplan.vue'),
            }
        },
        {
            path: 'objects',
            children: [
                {
                    path: '',
                    components: {
                        default:
                            () => import(/* webpackChunkName: "houses" */ '../../components/views/netplan/objects.vue'),
                        Sidebar:
                            () => import(/* webpackChunkName: "houses" */ '../../components/static/subMenu/netplan.vue'),
                    }
                },
                {
                    path: ':id',
                    components: {
                        default:
                            () => import(/* webpackChunkName: "houses" */ '../../components/views/netplan/manageObject.vue'),
                        Sidebar:
                            () => import(/* webpackChunkName: "houses" */ '../../components/static/subMenu/netplan.vue'),
                    }
                },
            ]
        },
        {
            path: 'customers',
            components: {
                default:
                    () => import(/* webpackChunkName: "house" */ '../../components/views/netplan/customers.vue'),
                Sidebar:
                    () => import(/* webpackChunkName: "houses" */ '../../components/static/subMenu/netplan.vue'),
            }
        },
        {
            path: 'errors',
            components: {
                default:
                    () => import(/* webpackChunkName: "house" */ '../../components/views/netplan/errors.vue'),
                Sidebar:
                    () => import(/* webpackChunkName: "houses" */ '../../components/static/subMenu/netplan.vue'),
            }
        },
    ],
}

module.exports = route;