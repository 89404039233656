const route = {
    path: '/settings',
    children: [
        {
            path: '',
            components: {
                default:
                    () => import(/* webpackChunkName: "settings" */ '../../components/views/settings.vue'),
                Sidebar:
                    () => import(/* webpackChunkName: "settings" */ '../../components/static/subMenu/settings.vue'),
            }
        },
        {
            path: 'profile',
            components: {
                default:
                    () => import(/* webpackChunkName: "settings" */ '../../components/views/settings/profile.vue'),
                Sidebar:
                    () => import(/* webpackChunkName: "settings" */ '../../components/static/subMenu/settings.vue'),
            }
        },
        {
            path: 'users',
            children: [
                {
                    path: '',
                    components: {
                        default:
                            () => import(/* webpackChunkName: "settings/users" */ '../../components/views/settings/users.vue'),
                        Sidebar:
                            () => import(/* webpackChunkName: "settings" */ '../../components/static/subMenu/settings.vue'),
                    }
                },
                {
                    path: 'new',
                    components: {
                        default:
                            () => import(/* webpackChunkName: "settings/users/new" */ '../../components/views/settings/addUser.vue'),
                        Sidebar:
                            () => import(/* webpackChunkName: "settings" */ '../../components/static/subMenu/settings.vue'),
                    }
                },
                {
                    path: ':id',
                    components: {
                        default:
                            () => import(/* webpackChunkName: "settings/users/edit" */ '../../components/views/settings/editUser.vue'),
                        Sidebar:
                            () => import(/* webpackChunkName: "settings" */ '../../components/static/subMenu/settings.vue'),
                    }
                },
            ],
        },
        {
            path: 'failures',
            children: [
                {
                    path: '',
                    components: {
                        default:
                            () => import(/* webpackChunkName: "settings/users/new" */ '../../components/views/settings/failures.vue'),
                        Sidebar:
                            () => import(/* webpackChunkName: "settings" */ '../../components/static/subMenu/settings.vue'),
                    }
                }, {
                    path: 'new',
                    components: {
                        default:
                            () => import(/* webpackChunkName: "settings/users/new" */ '../../components/views/settings/addFailure.vue'),
                        Sidebar:
                            () => import(/* webpackChunkName: "settings" */ '../../components/static/subMenu/settings.vue'),
                    }
                },
                {
                    path: ':id',
                    components: {
                        default:
                            () => import(/* webpackChunkName: "settings/users/edit" */ '../../components/views/settings/editFailure.vue'),
                        Sidebar:
                            () => import(/* webpackChunkName: "settings" */ '../../components/static/subMenu/settings.vue'),
                    }
                },
            ],
        },
        {
            path: 'materials',
            children: [
                {
                    path: 'new',
                    components: {
                        default:
                            () => import(/* webpackChunkName: "settings/users/new" */ '../../components/views/settings/manageMaterial.vue'),
                        Sidebar:
                            () => import(/* webpackChunkName: "settings" */ '../../components/static/subMenu/settings.vue'),
                    }
                },
                {
                    path: ':id',
                    components: {
                        default:
                            () => import(/* webpackChunkName: "settings/users/edit" */ '../../components/views/settings/manageMaterial.vue'),
                        Sidebar:
                            () => import(/* webpackChunkName: "settings" */ '../../components/static/subMenu/settings.vue'),
                    }
                }, {
                    path: '',
                    components: {
                        default:
                            () => import(/* webpackChunkName: "settings" */ '../../components/views/settings/materials.vue'),
                        Sidebar:
                            () => import(/* webpackChunkName: "settings" */ '../../components/static/subMenu/settings.vue'),
                    }
                },
            ],
        }, {
            path: 'ticketTemplates',
            children: [
                {
                    path: 'new',
                    components: {
                        default:
                            () => import(/* webpackChunkName: "settings/users/new" */ '../../components/views/settings/manageTicketTemplate.vue'),
                        Sidebar:
                            () => import(/* webpackChunkName: "settings" */ '../../components/static/subMenu/settings.vue'),
                    }
                },
                {
                    path: ':id',
                    components: {
                        default:
                            () => import(/* webpackChunkName: "settings/users/edit" */ '../../components/views/settings/manageTicketTemplate.vue'),
                        Sidebar:
                            () => import(/* webpackChunkName: "settings" */ '../../components/static/subMenu/settings.vue'),
                    }
                }, {
                    path: '',
                    components: {
                        default:
                            () => import(/* webpackChunkName: "settings" */ '../../components/views/settings/ticketTemplates.vue'),
                        Sidebar:
                            () => import(/* webpackChunkName: "settings" */ '../../components/static/subMenu/settings.vue'),
                    }
                },
            ],
        },
    ],
}

module.exports = route;