<template>
  <transition name="fade">
    <div v-if="visible" class="overlay">
      <div class="overlay-background"></div>
      <div class="overlay-box">
        <h3>{{ heading }}</h3>
        <div :class="'overlay-content' + this.isWarning ? ' warning' : ''" >
          <div class="overlay-main-content" v-html="overlayContent" />
          <div class="overlay-buttons">
            <div class="overlay-buttons-inner-wrapper">
              <SecondaryButton v-if="!this.isWarning" style="margin-right: 10px;" text="Abbrechen" @click="_cancel" />
              <PrimaryButton text="Bestätigen" @click="_confirm" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import PrimaryButton from "@/components/elements/PrimaryButton";
import SecondaryButton from "@/components/elements/SecondaryButton.vue";

export default {
  components: {
    SecondaryButton,
    PrimaryButton,
  },
  props: [
    "model",
  ],
  methods: {
    _confirm() {
      this._resolve(true);
      this.model.visible = false;
    },
    _cancel() {
      this._resolve(false);
      this.model.visible = false;
    },
  },
  computed: {
    cleanedModel() {
      if(typeof this.model === "object" && this.model !== null) {
        return this.model;
      } else {
        return {};
      }
    },
    heading() {
      if("heading" in this.cleanedModel && this.model.heading) {
        return this.model.heading;
      }
      return "Löschen bestätigen"
    },
    overlayContent() {
      if("text" in this.cleanedModel && this.model.text) {
        return this.model.text;
      }
      return "Sicher?";
    },
    visible() {
      return this.cleanedModel.visible === true;
    },
    isWarning() {
      return this.model.warning;
    },
    _resolve() {
      if(typeof this.cleanedModel.resolve === "function") {
        return this.cleanedModel.resolve;
      }
      return () => {
        console.warn("Resolve missing!");
      };
    },
  },
}
</script>