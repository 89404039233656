const route = {
    path: '/knowledge',
    children: [
        {
            path: '',
            components: {
                default:
                    () => import(/* webpackChunkName: "knowledge" */ '../../components/views/knowledge.vue'),
                Sidebar:
                    () => import(/* webpackChunkName: "knowledge" */ '../../components/static/subMenu/knowledge.vue'),
            }
        },
        {
            path: ':categoryId',
            components: {
                default:
                    () => import(/* webpackChunkName: "knowledge" */'../../components/views/knowledge.vue'),
                Sidebar:
                    () => import(/* webpackChunkName: "knowledge" */'../../components/static/subMenu/knowledge.vue')
            }
        },
        {
            path: 'manage',
            children: [
                {
                    path: '',
                    components: {
                        default:
                            () => import(/* webpackChunkName: "knowledge" */'../../components/views/knowledge/manageEntry.vue'),
                        Sidebar:
                            () => import(/* webpackChunkName: "knowledge" */'../../components/static/subMenu/knowledge.vue')
                    }
                },
                {
                    path: ':id',
                    components: {
                        default:
                            () => import(/* webpackChunkName: "knowledge" */'../../components/views/knowledge/manageEntry.vue'),
                        Sidebar:
                            () => import(/* webpackChunkName: "knowledge" */'../../components/static/subMenu/knowledge.vue')
                    }
                }
            ],
        }
    ],
}

module.exports = route;