// noinspection JSUnusedGlobalSymbols // All functions are used!

const {DateTime} = require("luxon");

export default {
    mounted() {
        window.addEventListener("scroll", (event) => {
            //this.updateScrollVariables();
        });
    },
    methods: {
        updateScrollVariables() {

            const r = document.querySelector(":root");
            r.style.setProperty('--scroll-x', (window.scrollX) + "px");
            r.style.setProperty('--scroll-y', (window.scrollY) + "px");
        },
        apiRequest: async function(method, endpoint, data = null, sendSession = true, fileUpload = false, raw = false) {
            const allowedMethods = ['POST', 'GET', 'PATCH', 'PUT', 'DELETE'];
            method = method.toUpperCase();
            if(!allowedMethods.includes(method)) {
                console.error("Forbidden method used!")
            }

            // noinspection JSUnresolvedVariable
            let url = this.apiBaseUrl + endpoint;
            const init = {
                method: method,
                cache: 'no-cache',
                headers: {},
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
            }

            if(sendSession) {
                init.headers.Authorization = 'Bearer ' + this.sessionKey;
            }

            if(method !== "GET" && method !== "DELETE") {
                if(!fileUpload)
                    init.body = JSON.stringify(data);
                else
                    init.body = data;
                if(!fileUpload)
                    init.headers['Content-Type'] = 'application/json';
            }

            const response = await fetch(url, init);
            const ret = {rawResponse: response, status: response.status, success: false};

            if(response.status === 404) {
                return ret;
            }

            if(response.status === 201 || response.status === 204) {
                ret.success = true;
                return ret;
            }

            if(raw) {
                return response;
            }


            let cnt;
            try {
                cnt = await response.json();
            } catch(err) {
                console.error(err);
                return ret;
            }
            ret.cnt = cnt;

            if((response.status === 403 && "error" in cnt && cnt.id === "authenticationFailed")
                || response.status === 401) {
                this.destroySession();
                return ret;
            }

            if(response.status === 200) {
                if(typeof cnt === "object") {
                    cnt.success = true;
                }
                return cnt;
            }

            return ret;
        },
        getFormData: function(id) {
            const form = document.getElementById(id);
            const formData = new FormData(form);
            const data = {};
            formData.forEach((value, key) => {
                if(key in data) {

                    if(Array.isArray(data[key])) {
                        data[key].push(value);
                    } else {
                        data[key] = [data[key], value];
                    }
                } else {
                    data[key] = value;
                }
            });
            return data;
        },
        globalValidate: function(event) {
            let parent = this;
            for(let i = 0; i < 10; i++) {
                if("formValid" in parent.$data) {
                    break;
                }
                if(parent.$parent !== null) {
                    parent = parent.$parent;
                } else {
                    break;
                }
            }

            if(!"formValid" in parent.$data) {
                console.warn("formValid not found!");
                return;
            }

            let valid = true;

            let form = event?.target?.form ?? event;
            if(form === null) {
                return;
            }
            if(!"elements" in form) {
                console.warn("elements of form not found!");
            }

            let elements = form.elements;

            for(const el of elements) {
                const ds = el.dataset;
                const val = el.value;


                if("number" in ds) {
                    const numberValue = parseFloat(val.replaceAll(",", "."));
                    if(isNaN(numberValue)) valid = false;

                    if("numberMin" in ds) {
                        if(numberValue < ds.numberMin) {
                            valid = false;
                        }
                    }
                    if("numberMax" in ds) {
                        if(numberValue > ds.numberMax) {
                            valid = false;
                        }
                    }
                }


                if("min" in ds) {
                    if(val.length < parseInt(ds.min, 10)) {
                        valid = false;
                    }
                }
                if("max" in ds) {
                    if(val.length > parseInt(ds.max, 10)) {
                        valid = false;
                    }
                }
                if("enum" in ds) {
                    if(!ds.enum.includes(val)) {
                        valid = false;
                    }
                }
                if("notIn" in ds) {
                    if(ds.notIn.includes(val)) {
                        valid = false;
                    }
                }
                if("hasCustomValidation" in ds && ds.hasCustomValidation === "true") {
                    if(!("customValid" in ds) || ds.customValid !== "true") {
                        valid = false;
                    }
                }

            }

            parent.$data.formValid = valid;
        },
        getSVG(icon) {
            try {
                return require('@/assets/img/svg/icons/' + icon + '.svg');
            } catch(e) {
                console.error("Icon " + icon + " not found!");
            }
        },
        getSVGImage(image) {
            try {
                return require('@/assets/img/svg/' + image);
            } catch(e) {
                console.error("Image " + image + " not found!");
            }
        },
        getImage(image) {
            try {
                return require('@/assets/img/' + image);
            } catch(e) {
                console.error("Image " + image + " not found!");
            }
        },
        confirm(text, heading = false) {
            const model = this.$store.state.confirmationOverlay;
            model.heading = heading;
            model.text = text;
            return new Promise((resolve, ignored_reject) => {
                model.resolve = resolve;
                model.visible = true;
            })
        }, warning(text, heading = false) {
            const model = this.$store.state.confirmationOverlay;
            model.heading = heading;
            model.text = text;
            model.warning = true;
            return new Promise((resolve, ignored_reject) => {
                model.resolve = resolve;
                model.visible = true;
            })
        },
        getItemById(id, data) {
            for(const elKey in data) {
                const el = data[elKey];
                if(el.id === id) {
                    return el;
                }
            }
            return {};
        },
        showAPIError(res, textInput) {
            console.log(res);
            let text = textInput;
            let type = "error";
            switch(res.status) {
                case 400:
                    if(res.cnt.id === "paramConstraint") {
                        type = "warning";
                        text += " " + res.cnt.msg;
                        if("additionalInformation" in res.cnt) {
                            text += " Folgender Constraint wurde nicht eingehalten: "
                                + res.cnt.additionalInformation.key + ": "
                                + res.cnt.additionalInformation.constraints.join(", ");
                        }
                    } else if(res.cnt.id === "missingParameter") {
                        text += " Folgende Eigenschaften fehlen: " + res.cnt.additionalInformation.missing.join(", ");
                    } else if(res.cnt.id === "wrongParamType") {
                        text += " " + res.cnt.msg;
                    } else {
                        text += " " + res.cnt.msg;
                    }
                    break;
                case 403:
                    text = "Fehler! Sie haben nicht die nötigen Rechte, um diese Aktion durchzuführen.";
                    break;
                case 404:
                    type = "warning";
                    text = "Fehler 404: Der angeforderte Inhalt konnte nicht gefunden werden.";
                    break;
                case 409:
                    text = "Conflict! " + res.cnt.id;
                    break;
                case 500:
                    text += "<br /><br />API-Fehler! Siehe JS-Console für weitere Details!";
                    break;
                default:
                    text += " " + res.cnt.msg;
            }

            this.showNotice({
                type,
                content: text,
            })
        },
        generateRandomString(length = 10) {
            const chars = "abcdefghjkmnpqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ123456789".split('');
            const array = new Uint8Array(length);
            self.crypto.getRandomValues(array);
            let out = "";
            for(const randInt of array) {
                const randIntInValueRange = randInt % chars.length;
                out += chars[randIntInValueRange];
            }
            return out;
        },
        formatDate(date) {
            return date.toLocaleDateString(undefined, {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });
        },
        setBreadCrumbs(breadCrumbs) {
            this.$store.state.breadCrumbs = breadCrumbs;
        },
        showNotice(model) {
            this.$store.commit("setToast", model);
        },
        formatRelativeDate(date) {
            return DateTime.fromJSDate(date).toRelative();
        },
        addParamsToLocation(params) {
            history.pushState(
                {},
                null,
                this.$route.path +
                '?' +
                Object.keys(params)
                    .map(key => {
                        return (
                            encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
                        )
                    })
                    .join('&')
            )
        },
        updateMouseCursor(event) {
            const r = document.querySelector(":root");
            r.style.setProperty('--mouse-x', event.pageX + "px");
            r.style.setProperty('--mouse-y', (event.pageY) + "px");
        },
        nl2br(str) {
            return (str + '').replaceAll("\r", "<br>").replaceAll("\n", "<br>");
        },
        br2nl(str) {
            return (str + '').replaceAll("<br>", "\n").replaceAll("</br>", "\n").replaceAll("<br />", "\n");
        }, waitForElement(selector, callback) {
            function checkForElement() {
                const element = document.querySelector(selector);

                if(element) {
                    callback(element);
                } else {
                    requestAnimationFrame(checkForElement);
                }
            }

            checkForElement();
        },
        convertCentsToPriceString(cents) {
            return ((parseInt(cents) / 100).toFixed(2) + " €").replaceAll(".", ",");
        }

    },
}