<template>
  <div :class="className">

    <input :id="'textInput_'+id"
           v-model="valueModel"
           :data-enum="getEnum"
           :data-max="max"
           :data-min="min"
           :data-not-in="notIn"
           :data-custom-valid="customValidateValid"
           :data-has-custom-validation="this.customValidate !== undefined && typeof this.customValidate === 'function'"
           :name="name"
           class="input input-text"
           placeholder=" "
           :type="getType"
           @keyup="validate"
           @focusout="validate"
           @change="validateIfDate"
           :autocomplete="autocomplete??'on'"
           :data-number="number"
           :data-number-max="numberMax"
           :data-number-min="numberMin"
           @click="openDateThing"
    >
    <span @click.prevent class="date-input-calendar" v-if="getType === 'date'"
          v-html="getSVG('calendar')" />
    <label :for="'textInput_'+id">{{ this.customPlaceholder ?? this.placeholder }}</label>
  </div>
</template>
<script>
import {v4 as uuid4} from "uuid";
import {nextTick} from "vue";

export default {
  data() {
    return {
      valid: true,
      id: null,
      customValidateValid: null,
      valueModel: '',
      customPlaceholder: null,
    }
  },
  props: [
    "name",
    "placeholder",
    "value",
    "class",
    "min",
    "max",
    "enum",
    "notIn",
    "formValidate",
    "type",
    "autocomplete",
    "customValidate",
    "customValidateInitial",
    "number",
    "numberMin",
    "numberMax"
  ],
  mounted() {
    this.id = uuid4();
    this.fullHTMLId = "textInput_" + this.id;
    this.valueModel = this.value;
    if(typeof this.customValidateInitial === "boolean") {
      this.customValidateValid = this.customValidateInitial;
    }
  },
  watch: {
    value(val, ignored_oldVal) {
      this.valueModel = val;
    }
  },
  methods: {
    openDateThing(event) {
      document.getElementById(this.fullHTMLId).showPicker();
    },
    async validateIfDate(event) {
      if(event.target.type === 'date') {
        await this.validate(event);
      }
    },
    async validate(event, noChange = false) {
      const el = event.target;
      const value = el.value;
      if(!noChange) {
        this.$emit("value-changed", value, event);
      }
      if(this.formValidate !== undefined && this.formValidate !== null && this.formValidate === false) {
        return;
      }

      let valid = true;

      if(this.customValidate !== undefined && typeof this.customValidate === 'function') {
        if(this.customValidate(value)) {
          this.customValidateValid = true;
        } else {
          valid = false;
          this.customValidateValid = false;
        }
      }

      await nextTick();

      this.globalValidate(event);

      if(this.number !== undefined && this.number) {
        const numberValue = parseFloat(value.replaceAll(",", "."));
        if(isNaN(numberValue)) valid = false;

        if(this.numberMin !== undefined) {
          if(numberValue < this.numberMin) {
            valid = false;
          }
        }
        if(this.numberMax !== undefined) {
          if(numberValue > this.numberMax) {
            valid = false;
          }
        }
      }

      if(this.min !== undefined) {
        if(value.length < this.min) {
          valid = false;
        }
      }
      if(this.max !== undefined) {
        if(value.length > this.max) {
          valid = false;
        }
      }
      if(this.enum !== undefined) {
        if(this.enum.includes(value)) {
          valid = false;
        }
      }
      if(this.notIn !== undefined) {
        if(this.notIn.includes(val)) {
          valid = false;
        }
      }
      this.valid = valid;
    }
  },
  computed: {
    className() {
      let className = this.class ?? '';
      if(this.valid !== undefined && !this.valid) {
        className += ' invalid';
      }
      return className + ' input-wrapper';
    },
    getType() {
      if(this.type) {
        return this.type;
      }
      return 'text';
    },
    getEnum() {
      return this.enum;
    },
  }
}
</script>