<template>
  <div :class="this.className">
    <input :id="'freeSearch_hidden_'+id"
           :data-enum="getEnum"
           :data-max="max"
           :data-min="min"
           :data-not-in="notIn"
           :data-custom-valid="customValidateValid"
           :data-has-custom-validation="this.customValidate !== undefined && typeof this.customValidate === 'function'"
           :name="name"
           type="hidden"
           @change="changeHidden"
    />
    <input :id="'freeSearch_'+id"
           :name="name+'_textField'"
           class="input input-text input-freesearch"
           placeholder=" "
           type="text"
           @focusout.self="focusout"
           @focus="focusin"
           @keyup="onkeyup"
           @focusin="focusin"
           @keydown.enter="handleEnter($event);"
           autocomplete="off"
    >
    <label :for="'textInput_'+id">{{ placeholder }}</label>
    <div class="freesearch-results" tabindex="0">
      <template v-if="Array.isArray(this.actions)">
        <div class="freesearch-result action" v-for="action in actions" @click="action.action">{{
            action.title
          }}
        </div>
      </template>
      <div v-for="val in data" class="freesearch-result" @click="(event)=> {setValue(val);}"
           tabindex="0">{{
          val.title
        }}
      </div>
    </div>
  </div>
</template>
<script>
import {v4 as uuid4} from "uuid";
import {nextTick} from "vue";

export default {
  data() {
    return {
      valid: true,
      id: null,
      customValidateValid: null,
      valueModel: '',
      termModel: '',
      data: [],
      tempValue: '',
    }
  },
  props: [
    "name",
    "placeholder",
    "value",
    "term",
    "class",
    "min",
    "max",
    "enum",
    "notIn",
    "formValidate",
    "customValidate",
    "customValidateInitial",
    "filterFunction",
    "getDataOnInit",
    "actions",
    "insertOnSelect"
  ],
  created() {
    this.id = uuid4();
  },
  mounted() {
    this.valueModel = this.value;
    this.termModel = this.term;
    if(typeof this.customValidateInitial === "boolean") {
      this.customValidateValid = this.customValidateInitial;
    }
    if(typeof this.getDataOnInit === "boolean" && this.getDataOnInit) {
      this.getData();
    }
    nextTick().then(() => {
      document.getElementById("freeSearch_hidden_" + this.id).value = this.value ?? '';
      document.getElementById("freeSearch_" + this.id).value = this.term ?? '';
    });

  },
  watch: {
    value(val, ignored_oldVal) {
    },
    term(val, ignored_oldVal) {
      this.termModel = val;
    },
    data(val, ignored_oldVal) {
      for(const el of val) {
        if(el.title === this.termModel) {
          this.valueModel = el.id;
          return;
        }
      }
    },
  },
  computed: {
    className() {
      let className = this.class ?? '';
      if(this.valid !== undefined && !this.valid) {
        className += ' invalid';
      }
      let final = className + ' input-wrapper input-freesearch-wrapper';
      return final;
    },
    getEnum() {
      return this.enum;
    },
  },
  methods: {
    changeHidden(event) { },
    focusin(event) {
      this.tempValue = event.target.value;
      setTimeout(() => {
        this.refresh(false, true);
      }, 100);
    },
    focusout(event) {
      this.$emit('input-focusout', event);
      this.validate(event, event.target.value === this.tempValue);
    },
    refresh(value, focusIn = false) {
      if(value === false) {
        value = document.getElementById("freeSearch_" + this.id).value;
        this.termModel = value;
      }
      if(value === undefined) {
        return;
      }
      const event = {};
      event.target = document.getElementById('freeSearch_hidden_' + this.id);
      event.value = event.target?.value ?? '';
      if(value.length === 0 && !focusIn) {
        this.valueModel = '';
        this.validate(event);
      }
      this.getData();
    },
    setValue(result) {
      if(this.insertOnSelect === undefined || this.insertOnSelect) {
        const elementById = document.getElementById('freeSearch_hidden_' + this.id);
        elementById.value = '' + result.id;
        document.getElementById('freeSearch_' + this.id).value = result.title;
      }

      this.$emit('valueSelected', result, this.id);
      setTimeout(() => {
        document.activeElement.blur();
        this.validate({
          target: document.querySelector("#freeSearch_" + this.id),
        })
      }, 10);
    },
    handleEnter(event) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      document.activeElement.blur();

    },
    async getData() {
      // ToDo: Abfangen, wenn mehrere Abfragen aufgrund von Netzwerk-Schwankungen in der falschen Reihenfolge resolven!
      if(typeof this.filterFunction !== "function") {
        this.data = [];
      }
      this.data = await this.filterFunction(document.getElementById('freeSearch_' + this.id).value);
    },
    async onkeyup(event) {
      await this.validate(event);
      this.refresh(document.getElementById('freeSearch_' + this.id).value);
    },
    async validate(event, noChange = false) {
      const el = event.target;
      const value = el.value;
      if(!noChange) {
        this.$emit("value-changed", value);
      }
      if(this.formValidate !== undefined && this.formValidate !== null && this.formValidate === false) {
        return;
      }

      let valid = true;

      if(this.customValidate !== undefined && typeof this.customValidate === 'function') {
        if(this.customValidate(value)) {
          this.customValidateValid = true;
        } else {
          valid = false;
          this.customValidateValid = false;
        }
      }

      await nextTick();

      this.globalValidate(event);

      if(this.min !== undefined) {
        if(value.length < this.min) {
          valid = false;
        }
      }
      if(this.max !== undefined) {
        if(value.length > this.max) {
          valid = false;
        }
      }
      if(this.enum !== undefined) {
        if(this.enum.includes(value)) {
          valid = false;
        }
      }
      if(this.notIn !== undefined) {
        if(this.notIn.includes(value)) {
          valid = false;
        }
      }
      this.valid = valid;

    }
  },
}
</script>