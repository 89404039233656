const route = {
    path: '/statistics',
    children: [
        {
            path: '',
            components: {
                default:
                    () => import(/* webpackChunkName: "statistics" */ '../../components/views/statistics.vue'),
                Sidebar:
                    () => import(/* webpackChunkName: "statistics" */ '../../components/static/subMenu/statistics.vue'),
            }
        }
    ],
}

module.exports = route;