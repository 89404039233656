<template>
  <div>
    <div class="sidebar-menu-header knowledge-menu-header">
      <span v-html="getSVG('knowledge')"/>
      <h1>Wissen</h1>
    </div>
  </div>
  <div class="sidebar-links" v-if="this.treeData.list">
    <div class="sidebar-link-area">
      <div class="knowledge-sidebar">
        <KnowledgeTree
            :key="this.counterThing"
            :tree="this.treeData?.list"
        />
      </div>
    </div>
  </div>

</template>


<script>

import editor from "quill/core/editor";
import TicketDetailsAccordion from "@/components/elements/TicketDetailsAccordion.vue";
import KnowledgeTree from "@/components/elements/KnowledgeTree.vue";


export default {
  computed: {
    editor() {
      return editor
    },
  },
  data() {
    return {
      counterThing: 0,
    }
  },
  components: {
    KnowledgeTree,
    TicketDetailsAccordion
  },
  methods: {

    linkTo(to) {
      this.$router.push({path: '/knowledge/' + to});
    }
  },
  created() {
    this.loadStuff();
  },
  mounted() {
    this.emitter.on('new-category', async (evt) => {
      await this.loadStuff();
    });
    this.emitter.on('remove-category', async (evt) => {
      await this.loadStuff();
    });
    this.emitter.on('new-entry', async (evt) => {
      await this.loadStuff();
    });
    this.emitter.on('remove-entry', async (evt) => {
      await this.loadStuff();
    });

    this.emitter.on('load-entry', async (event) => {
      console.log("load entry");
      await this.nullEntry();
    })



  },
  watch: {
    counter(old, newVal) {
      console.log(newVal);
    }
  }
}
</script>
