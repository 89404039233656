<template>
  <div class="main-menu">
    <div class="main-menu-logo" v-html="getSVGImage('image_mark.svg')" />
    <div class="main-menu-links">
      <div class="main-menu-link-container" v-for="route in getUserPages">


        <router-link :data-display-name="this.titles[route]"
                     :to="'/'+(route === 'dashboard' ? '' : route)"

        >
          <span v-html="getSVG(route)" />
        </router-link>
      </div>
    </div>

    <div class="main-menu-user">
      <router-link to="/settings/profile">
        <div class="main-menu-user-image placeholder" :style="getPlaceholderImage" />
        <div class="main-menu-user-image actual" :style="getUserImage" />
      </router-link>
      <div class="main-menu-theme-switcher" v-html="getSVG(getThemeSwitcher)"
           @click="$store.commit('toggleLightMode')" />
      <div v-html="getSVG('logout')" class="main-menu-logout" @click="destroySession" />
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      titles: {
        dashboard: 'Dashboard',
        tickets: 'Tickets',
        netplan: 'Netz',
        calendar: 'Kalender',
        houses: 'Objekte',
        knowledge: 'Wissen',
        statistics: 'Statistiken',
        settings: 'Einstellungen'
      },
      firstKnowledgeCategory: null,
    };
  },
  async mounted() {
    for(let link of document.getElementsByClassName("main-menu-link-container")) {
      link.addEventListener("mouseleave", (e) => {
        e.target.classList.add("removed-hover");
        setTimeout(() => {
          e.target.classList.remove("removed-hover");
        }, 500);
      });
    }
    this.firstKnowledgeCategory = await this.apiRequest("GET", "/category");
    this.firstKnowledgeCategory = this.firstKnowledgeCategory[0].id ?? "";
  },
  methods: {
    setFirstCategory() {
      console.log("hi")
      console.log(window.location.href)
      //this.$router.push({path: '/knowledge/'+this.firstKnowledgeCategory})
    },
  },
  computed: {
    getUserPages() {
      const capabilities = this.$store.state?.user?.capabilities ?? [];
      let pages = [];
      if(capabilities.includes("default")) {
        pages = pages.concat(['dashboard', 'tickets', 'netplan', 'calendar', 'knowledge', 'statistics']);
      }
      if(capabilities.includes("advanced")) {
        pages = pages.concat(['settings']);
      }
      return pages;
    },
    getPlaceholderImage() {
      if(this.$store.state.lightMode) {
        return 'background-image: url(' + this.getImage('user_light.svg') + ');';
      }
      return 'background-image: url(' + this.getImage('user.svg') + ');';
    },
    getUserImage() {
      if((this.$store.state?.user?.image ?? null) !== null) {
        return 'background-image: url(' + this.$store.state.user.image + ');';
      }
      if(this.$store.state.lightMode) {
        return 'background-image: url(' + this.getImage('user_light.svg') + ');';
      }
      return 'background-image: url(' + this.getImage('user.svg') + ');';
    },
    getThemeSwitcher() {
      return !this.$store.state.lightMode ? 'sun' : 'moon';
    }
  },
  watch: {
    $route(to, from) {
      this.from = to.fullPath;
      if(this.from === '/knowledge') {
        this.$router.push({path: '/knowledge/' + this.firstKnowledgeCategory});
      }

    }
  }

}
</script>
