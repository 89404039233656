import ManageEntry from "@/components/views/knowledge/manageEntry.vue";

export default {
    data() {
        return {
            displayCategoryC: false,
            currentCategoryId: false,
            categories: [],
            treeData: {
                list: null,
            },
            entryData: false,
            counter: 0,
            entries: [],
        }
    },
    components: {
        ManageEntry
    },
    methods: {
        createCategory() {
            this.displayCategoryC = !this.displayCategoryC;
        },
        createEntry(category) {
            (parseInt(category) !== 1) ? this.$router.push({
                path: '/knowledge/manage',
                query: {categoryId: category}
            }) : this.$router.push({path: '/knowledge/manage'});
            console.log("category")
            console.log(category)
        },
        async getCategories() {
            this.categories = await this.apiRequest("GET", "/category");

        },
        async getEntries() {
            this.entries = await this.apiRequest("GET", "/wiki");
        },
        async loadStuff() {
            await this.getCategories();
            await this.getEntries();
            for (let category of this.categories) {
                category.type = "category";
            }

            for (let entry of this.entries) {
                entry.type = "entry";
            }

            const categories = [...this.categories.concat(...this.entries)];
            this.treeData.list = this.buildTree(categories);
            this.treeData.counter = this.treeData.counter + 1;
            return this.treeData.list;
        },
        buildTree(categories) {
            const entryMap = {};
            const roots = [];

            // Sortiere die Einträge nach ihrer ID, um sicherzustellen, dass ihre
            // Eltern immer zuerst hinzugefügt werden.
            categories.sort((a, b) => a.id - b.id);

            // Füge jedem Eintrag ein leeres children-Array hinzu und füge ihn
            // der entryMap hinzu.
            categories.forEach(entry => {
                entry.children = [];
                entryMap[entry.id] = entry;
            });

            // Durchlaufe alle Einträge und füge sie der entsprechenden Elternkategorie hinzu.
            categories.forEach(entry => {
                const parent = entryMap[entry.parent_entry] ?? entryMap[entry.parent_category];
                if (parent) {
                    parent.children.push(entry);

                } else {
                    roots.push(entry);
                }
            });

            return roots;
        },
        reloadMenu() {
            this.treeData.counter = this.treeData.counter + 1;
            this.counter++;
        },
        reloadEntry() {

        },
        updateCurrentSidebar() {
            let categories = this.apiRequest("GET", "/category");
            console.log("categories")

            console.log(categories)
        },

        loadEntry() {
            this.data = this.apiRequest("GET", `/wiki/${this.id}`);
            let category = this.categories.find(c => c.id === this.data.parentCategory);

            this.data.relatedTo = {
                id: category?.id ?? '',
                title: category?.title ?? ''
            };

            this.category = this.data.relatedTo.id;
            this.currentCategoryTitle = this.data.relatedTo.title;
            this.currentCategoryId = this.data.relatedTo.id;
            this.entryChanges = this.apiRequest("GET", "/wiki/history/" + this.id);
            this.data.lastCategory = this.categories.filter(item => item.id === this.data?.relatedTo.id).map(i => i.id);

        },

        nullEntry() {
            console.log(this.$data);
        }



    }
}

