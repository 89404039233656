<template>
  <div :id="this.id" class="file-upload-table">

    <DynamicTable
        :buttons="[
          {
            icon: 'rename',
            action: editFileName
          },
          {
            icon:'delete',
            action:deleteFile,
          },
      ]"
        :columns="['name','date']"
        :data="this.getFiles"
        :links="this.links"
        :linksNewTab="true"
        :previewImages="this.previewImages"
        :column-names="{
            name:'Name',
            date:'Datum',
        }"
        :select-options="[
          {
            name:'Löschen',
            action: deleteMultipleFiles,
          },
      ]"
        :count="5"
        v-if="getFiles!==null&&getFiles!==false&&getFiles.length>0"
    />
    <input accept=".pdf,.txt,image/*,video/*" type="file" ref="file" style="display: none" @change="onFileChoose" />
    <SecondaryButton @click.prevent="$refs.file.click()" text="Datei hinzufügen" />
  </div>

</template>


<script>
import DynamicTable from "@/components/elements/DynamicTable.vue";
import SecondaryButton from "@/components/elements/SecondaryButton.vue";
import {DateTime} from "luxon";
import {v4 as uuid4} from "uuid";
import {nextTick} from "vue";

export default {
  data() {
    return {
      allowedMimeTypes: [
        'application/pdf',
        'text/plain',
        'image/jpeg',
        'image/jpg',
        'image/png',
        'image/gif',
        'image/webp',
        'video/mp4',
        'video/ogg',
        'video/webm',
        'image/svg+xml'
      ],
      id: null,
      files: [],
      links: {},
      previewImages: {},
      blob: null,
      customFocusout: false,
      tempFiles: [],
    }
  },
  created() {
    this.id = uuid4();
  },
  computed: {
    getFiles() {
      return this.files;
    },
    DateTime() {
      return DateTime
    },
  },
  async mounted() {
    let that = this;
    await nextTick();
    const container = document.getElementById(this.id);

    container.addEventListener('drop', function(e) {
      e.preventDefault();
      if(e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length) {
        let file = e.dataTransfer.files[0];
        that.addFile(file);
      }
    });

    container.addEventListener('dragover', function(e) {
      e.preventDefault();
    });

    container.addEventListener('dragleave', function(e) {
      e.preventDefault();
    });
    await this.loadFiles();
  },
  props: ['linkType', 'linkId', 'saveTemporary'],
  components: {SecondaryButton, DynamicTable},
  methods: {
    async uploadTempFiles(linkType, linkId) {
      const formData = new FormData();
      for(let file of this.tempFiles) {
        console.log(file);
        if(!this.allowedMimeTypes.includes(file.file.type)) {
          this.showNotice({
            type: "error",
            content: 'Ungültiger Dateityp für Datei "' + file.name + '"! Es sind ausschließlich PDFs, Bilder, Videos und Text-Dateien erlaubt!',
          });
          continue;
        }
        formData.append("files[]", file.file);
      }
      const res = await this.apiRequest("POST", `/file-upload/${linkType}/${linkId}`, formData, true, true);
      if(!res.success) {
        if(res.cnt.id === 'invalidFileType') {
          this.showNotice({
            type: "error",
            content: "Ungültiger Dateityp! Es sind ausschließlich PDFs, Bilder, Videos und Text-Dateien erlaubt!",
          });
        }
      }
    },
    async loadFiles() {
      let fileInfos;
      if(this.linkId)
        fileInfos = await this.apiRequest(
            "GET",
            "/file-upload/" + this.linkType + "/" + this.linkId,
        );


      this.links.name = [];
      this.previewImages.name = [];
      this.files = [];
      if(fileInfos && fileInfos.length > 0) {
        let tempFiles = [];
        for(const fileInfo of fileInfos) {
          const id = fileInfo.id;
          const name = fileInfo.originalFileName;
          const date = DateTime.fromJSDate(new Date(fileInfo.uploadDate)).toFormat("dd.MM.yyyy HH:mm");
          const fileObject = await this.apiRequest("GET", "/file-upload/" + id, null, true, false, true);
          const blob = URL.createObjectURL(await fileObject.blob());
          tempFiles.push({id, name, date, blob});
          this.links.name.push(blob);
          this.previewImages.name.push(blob);
        }
        this.files = tempFiles;

      }
    },
    addFile(file) {
      const formData = new FormData();
      if(!this.allowedMimeTypes.includes(file.type)) {
        this.showNotice({
          type: "error",
          content: 'Ungültiger Dateityp für Datei "' + file.name + '"! Es sind ausschließlich PDFs, Bilder, Videos und Text-Dateien erlaubt!',
        });
        return;
      }
      formData.append("files[]", file);
      if(this.saveTemporary) {
        const id = -1;
        const name = file.name;
        const date = DateTime.now().toFormat("dd.MM.yyyy HH:mm");
        const blob = URL.createObjectURL(file);
        const items = {id, name, date, blob, file};
        this.files.push(items);
        this.tempFiles.push(items);
        this.links.name.push(blob);
        this.previewImages.name.push(blob);
      }

      if(!this.saveTemporary)
        this.apiRequest("POST", `/file-upload/${this.linkType}/${this.linkId}`, formData, true, true).then(async r => {
          for(const id in r.ids) {
            const info = r.ids[id];
            const fileObject = await this.apiRequest("GET", "/file-upload/" + id, null, true, false, true);
            const name = info.originalFileName;
            const date = DateTime.fromJSDate(new Date(info.uploadDate)).toFormat("dd.MM.yyyy HH:mm");
            const blob = URL.createObjectURL(await fileObject.blob());
            this.files.push({id, name, date, blob});
            this.links.name.push(blob);
            this.previewImages.name.push(blob);
          }
        });
    },
    onFileChoose(event) {
      const form = document.getElementById(this.id).closest("form");
      if(form)
        this.globalValidate(form);

      const formData = new FormData();
      for(let file of this.$refs.file.files) {
        if(!this.allowedMimeTypes.includes(file.type)) {
          this.showNotice({
            type: "error",
            content: 'Ungültiger Dateityp für Datei "' + file.name + '"! Es sind ausschließlich PDFs, Bilder, Videos und Text-Dateien erlaubt!',
          });
          continue;
        }
        formData.append("files[]", file);
        if(this.saveTemporary) {
          const id = -1;
          const name = file.name;
          const date = DateTime.now().toFormat("dd.MM.yyyy HH:mm");
          const blob = URL.createObjectURL(file);
          const items = {id, name, date, blob, file};
          this.files.push(items);
          this.tempFiles.push(items);
          this.links.name.push(blob);
          this.previewImages.name.push(blob);
        }

      }
      if(!this.saveTemporary)
        this.apiRequest("POST", `/file-upload/${this.linkType}/${this.linkId}`, formData, true, true).then(async r => {
          for(const id in r.ids) {
            const info = r.ids[id];
            const fileObject = await this.apiRequest("GET", "/file-upload/" + id, null, true, false, true);
            const name = info.originalFileName;
            const date = DateTime.fromJSDate(new Date(info.uploadDate)).toFormat("dd.MM.yyyy HH:mm");
            const blob = URL.createObjectURL(await fileObject.blob());
            this.files.push({id, name, date, blob});
            this.links.name.push(blob);
            this.previewImages.name.push(blob);
          }
        });
    },
    editFileName(id) {
      const row = (document.querySelector(`tr[data-id='${id}']`));
      const cellText = row.querySelector(".name").querySelector(".table-cell-text");
      if(cellText.contentEditable === true) {
        this.stopEditingFileName(id);
        return;
      }
      cellText.contentEditable = true;
      cellText.focus();
      const buttonCol = row.querySelector(".button-col");
      const editButton = buttonCol.querySelector("button");
      editButton.innerHTML = this.getSVG('check_border');
      const currentName = cellText.innerHTML;
      const currentNameSplit = currentName.split(".");
      const end = currentNameSplit[currentNameSplit.length - 1];
      cellText.innerHTML = currentName.split('.').slice(0, -1).join('.');

      const a = row.querySelector(".name").querySelector("a");
      a.dataset.prevent = "true";
      const stop = () => {
        cellText.innerHTML = cellText.innerHTML + "." + end;
        this.stopEditingFileName(id);
        a.dataset.prevent = "false";
        const elClone = cellText.cloneNode(true);
        cellText.parentNode.replaceChild(elClone, cellText);
      };

      cellText.addEventListener("keydown", (e) => {
        if(e.key.toLowerCase() === 'escape' || e.key.toLowerCase() === 'enter') {
          this.customFocusout = true;
          stop();
          setTimeout(() => {
            this.customFocusout = false;
          }, 1);
          e.preventDefault();
        }
      });
      cellText.addEventListener("focusout", (e) => {
        if(!this.customFocusout)
          stop();
      });
    },
    stopEditingFileName(id) {
      const row = (document.querySelector(`tr[data-id='${id}']`));
      const buttonCol = row.querySelector(".button-col");
      const editButton = buttonCol.querySelector("button");
      editButton.innerHTML = this.getSVG('rename');
      const cellText = row.querySelector(".name").querySelector(".table-cell-text");
      cellText.contentEditable = false;
      cellText.blur();
      this.apiRequest("PATCH", `/file-upload/${id}`, {title: cellText.innerHTML}).then(r => {
      });
    },
    async deleteFile(id) {
      if(await this.confirm("Möchten Sie die ausgewählte Datei wirklich löschen?")) {
        this.deleteFileRaw(id);
      }
    },
    deleteFileRaw(id) {
      this.apiRequest("DELETE", `/file-upload/${id}`).then(r => {
        const index = this.files.findIndex(e => e.id === parseInt(id));
        this.files.splice(index, 1);
        this.previewImages.name.splice(index, 1);
        this.links.name.splice(index, 1);
      });
    },
    async deleteMultipleFiles(ids) {
      if(ids.length === 0) {
        this.showNotice({
          type: "warning",
          content: "Bitte wählen Sie eine Datei!",
        });
        return;
      }

      if(await this.confirm("Möchten Sie die ausgewählten Dateien wirklich löschen?")) {
        ids.forEach(this.deleteFileRaw);
      }
    }
  }
}
</script>