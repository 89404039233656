<template>
  <template v-if="loggedIn">
    <ConfirmationOverlay :model="$store.state.confirmationOverlay" />
    <NoticeToast :model="$store.state.toast" />
    <MainMenu />
    <HeaderBar />
    <div class="sidebar" v-if="$store.state.pageTitle !== 'Dashboard'" >
      <router-view name="Sidebar" />
    </div>
    <div class="wrapper" :class="{'withoutSidebar': $store.state.pageTitle === 'Dashboard'}">
      <div class="breadcrumb-wrapper" v-if="$store.state.breadCrumbs.length>0">
        <router-link :to="bc.link" class="breadcrumb" v-for="bc in $store.state.breadCrumbs">
          <span v-html="getSVG('arrow_left')" />
          {{ bc.title }}
        </router-link>
      </div>
      <router-view />
    </div>
  </template>
  <Login v-else />
</template>

<script>
import Login from "@/components/views/login.vue";
import MainMenu from "@/components/static/MainMenu.vue";
import HeaderBar from "@/components/static/HeaderBar.vue";
import ConfirmationOverlay from "@/components/elements/ConfirmationOverlay.vue";
import NoticeToast from "@/components/elements/NoticeToast.vue";

export default {
  components: {
    Login,
    MainMenu,
    HeaderBar,
    ConfirmationOverlay,
    NoticeToast,
  },
  computed: {
    loggedIn() {
      return this.reloadUser();
    },
  }
}
</script>
