const route = {
    path: '/tickets',
    children: [
        {
            path: '',
            components: {
                default:
                    () => import(/* webpackChunkName: "tickets" */ '../../components/views/tickets.vue'),
                Sidebar:
                    () => import(/* webpackChunkName: "tickets" */ '../../components/static/subMenu/tickets.vue'),
            }
        },
        {
            path: 'manage',
            children: [
                {
                    path: '',
                    components: {
                        default:
                            () => import(/* webpackChunkName: "ticket" */ '../../components/views/tickets/manageTicket.vue'),
                        Sidebar:
                            () => import(/* webpackChunkName: "tickets" */ '../../components/static/subMenu/tickets.vue'),
                    }
                },
                {
                    path: ':id',
                    components: {
                        default:
                            () => import(/* webpackChunkName: "ticket" */ '../../components/views/tickets/manageTicket.vue'),
                        Sidebar:
                            () => import(/* webpackChunkName: "tickets" */ '../../components/static/subMenu/tickets.vue'),
                    }
                },
            ]
        },
        {
            path: 'installations',
            children: [
                {
                    path: 'manage',
                    children: [
                        {
                            path: '',
                            components: {
                                default:
                                    () => import(/* webpackChunkName: "tickets" */ '../../components/views/tickets/manageInstallations.vue'),
                                Sidebar:
                                    () => import(/* webpackChunkName: "tickets" */ '../../components/static/subMenu/installations.vue'),
                            }
                        },
                        {
                            path: ':id',
                            components: {
                                default:
                                    () => import(/* webpackChunkName: "tickets" */ '../../components/views/tickets/manageInstallations.vue'),
                                Sidebar:
                                    () => import(/* webpackChunkName: "tickets" */ '../../components/static/subMenu/installations.vue'),
                            }
                        }
                    ],

                }
            ],
        },
        {
            path: ':type',
            components: {
                default:
                    () => import(/* webpackChunkName: "tickets" */ '../../components/views/tickets.vue'),
                Sidebar:
                    () => import(/* webpackChunkName: "tickets" */ '../../components/static/subMenu/tickets.vue'),
            }
        }
    ],
}

module.exports = route;