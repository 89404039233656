export default {
    data() {
        return {
            installationAttributes: {
              units: {
                  piece: 'Stück',
                  meter: 'Meter',
              }
            },
            ticketAttributes: {
                contactTypes: [
                    {
                        id: 'phone',
                        title:'Telefon',
                    },
                    {
                        id: 'email',
                        title: 'E-Mail',
                    },
                    {
                        id: 'automatic_email',
                        title: 'Automatische E-Mail-Erkennung',
                    },
                    {
                        id: 'personal',
                        title: 'Persönlich',

                    },
                    {
                        id: 'misc',
                        title: 'Sonstiges',
                    },
                ],
                businessAreas: [
                    {
                        id: 'internet',
                        title: 'Internet',
                    },
                    {
                        id: 'telephone',
                        title: 'Telefon',
                    },
                    {
                        id: 'television',
                        title: 'TV',
                    },
                    {
                        id: 'hosting',
                        title: 'Hosting',
                    }
                ],
                types: [
                    {
                        id: 'error',
                        title: 'Störung',
                        color: 'signal-dark-red'
                    },
                    {
                        id: 'deployment',
                        title: 'Bereitstellung',
                        color: 'signal-blue'
                    },
                    {
                        id: 'support',
                        title: 'Support',
                        color: 'signal-orange'
                    },
                    {
                        id: 'request',
                        title: 'Anfrage',
                        color: 'signal-purple'

                    },
                ],
                statuses: [
                    {
                        id: 'open',
                        title: 'Offen',
                        color: 'signal-dark-red'
                    },
                    {
                        id: 'in_process',
                        title: 'In Bearbeitung',
                        color: 'signal-orange'
                    },
                    {
                        id: 'closed',
                        title: 'Geschlossen',
                        color: 'signal-light-green'
                    },
                    {
                        id: 'on_hold',
                        title: 'Zurückgestellt',
                        color: 'notice-blue'

                    },
                    {
                        id: 'rejected',
                        title: 'Abgelehnt',
                        color: 'signal-grey'

                    },
                    {
                        id: 'to_confirm',
                        title: 'Zu bestätigen',
                        color: 'signal-light-red'

                    },
                ],
                priorities: [
                    {
                        id: 'lowest',
                        title: 'Sehr niedrig',
                        color: 'signal-grey-green'
                    },
                    {
                        id: 'low',
                        title: 'Niedrig',
                        color: 'signal-light-green'
                    },
                    {
                        id: 'mid',
                        title: 'Normal',
                        color: 'signal-dark-green'
                    },
                    {
                        id: 'high',
                        title: 'Hoch',
                        color: 'signal-light-red'

                    },
                    {
                        id: 'highest',
                        title: 'Kritisch',
                        color: 'signal-dark-red'
                    }
                ]
            }
        };
    }
}