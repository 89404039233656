<template>
  <div :class="getClassNames" :id="this.id">
    <quill-editor :toolbar="getToolbar" @ready="ready" :placeholder="this.placeholder"
                  @textChange="onUpdate"
                  theme="snow" />
    <div v-if="this.templates && Array.isArray(this.templates)" :class="getOverlayClass">
      <div class="template-overlay-text">Schreiben beginnen oder Vorlage auswählen...</div>
      <FreeSearch placeholder="Vorlage auswählen"
                  :filter-function="templateFilter"
                  name="template"
                  @value-selected="templateSelected"
                  @value-changed="templateValueChanged"
                  :insert-on-select="false"
                  :formValidate="false"
      />
    </div>
  </div>
</template>

<script>

import '@vueup/vue-quill/dist/vue-quill.snow.css';
import {QuillEditor} from '@vueup/vue-quill'
import FreeSearch from '@/components/elements/FreeSearch.vue';
import {v4 as uuid4} from "uuid";

export default {
  name: "WYSIWYGEditor",
  components: {QuillEditor, FreeSearch},
  props: [
    'name',
    'value',
    'placeholder',
    'class',
    'headers',
    'templates'
  ],
  data() {
    return {
      id: null,
      content: null,
      quill: null,
      currentValue: '',
      currentText: '',
    }
  },
  methods: {
    templateValueChanged(event) {

    },
    templateSelected(val) {
      this.quill.container.firstChild.innerHTML = val.description;
      this.onUpdate();
    },
    templateFilter(term) {
      return this.templates.filter(template => template.title.toLowerCase().includes(term.toLowerCase()));
    },
    ready(quill) {
      this.quill = quill;
      quill.container.firstChild.innerHTML = this.value;
      this.currentValue = this.value;
      this.currentText = quill.getText();

      let that = this;

      quill.root.addEventListener('drop', function(e) {
        e.preventDefault();
        if(e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length) {
          let file = e.dataTransfer.files[0];
          that.$emit('dropFile', file);
        }
      });

      quill.root.addEventListener('dragover', function(e) {
        e.preventDefault();
      });

      quill.root.addEventListener('dragleave', function(e) {
        e.preventDefault();
      });

    },
    onUpdate(event) {
      this.currentValue = this.quill.container.firstChild.innerHTML;
      this.currentText = this.quill.getText();
      this.$emit('updateContent', this.currentValue);
      this.globalValidate(document.getElementById(this.id).closest("form"));
    },
  },
  created() {
    this.id = uuid4();
  },
  computed: {
    getOverlayClass() {
      let extra = "";
      if(this.currentText.trim() !== '') {
        extra = "hidden";
      }

      return 'template-overlay ' + extra;
    },
    getToolbar() {
      const toolbar = [];
      if(this.headers) {
        toolbar.push([{'size': ['small', false, 'large', 'huge']}]);
      }
      toolbar.push(['bold', 'italic', 'underline', 'strike']);
      toolbar.push(['link']);
      toolbar.push([{'list': 'ordered'}, {'list': 'bullet'}]);
      toolbar.push([{'indent': '-1'}, {'indent': '+1'}]);


      return toolbar;
    },
    getClassNames() {
      const classes = ['input-wrapper', 'wysiwyg', this.class];
      return classes.join(" ");
    }
  }
}
</script>