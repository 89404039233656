<template>
  <div class="file-uploader-wrapper">
    <label class="file-input-label" :for="'file-input-'+this.id">Datei auswählen</label>
    <input :id="'file-input-'+this.id" multiple="multiple" type="file" @change="uploadFile" ref="file">
    <output class="file-input-output">{{ getSelectedFiles }}</output>
    <PrimaryButton @click="submitFile" text="Hochladen"/>
  </div>
</template>

<script>
import PrimaryButton from "@/components/elements/PrimaryButton.vue";
import {v4 as uuid4} from "uuid";

export default {
  name: "FileUploader",
  components: {PrimaryButton},
  data() {
    return {
      id: null,
      files: [],
    }
  },
  mounted() {
    this.id = uuid4();
  },
  computed: {
    getSelectedFiles() {
      if(this.files.length === 0) {
        return "Datei auswählen";
      } else if(this.files.length === 1) {
        return this.files[0].name;
      } else {
        return this.files.length + " Dateien ausgewählt";
      }
    }
  },
  methods: {
    uploadFile() {
      this.files = this.$refs.file.files;
    },
    async submitFile() {
      const formData = new FormData();
      for (let file of this.files) {
          formData.append("files[]", file);
        formData.append("relatedEntry", this.$route.params.id);
        console.log(formData)
      }
      this.apiRequest("POST", `/file-upload`, formData, true, true).then(r => {
      });
    }
  }
}
</script>