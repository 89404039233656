<template>
  <div class="header-bar-box" :style="this.visibility ? 'visibility: visible;' : 'visibility: hidden;'">
    <h3>Status</h3>
  </div>
</template>

<script>
export default {
  props: [
    "visibility",
  ],
}
</script>