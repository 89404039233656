export default {
    computed: {
        sessionKey() {
            // noinspection BadExpressionStatementJS // Used to trigger recomputation
            this.$store.state.reevaluateLogin;
            return this.$cookies.get("sessionKey");
        }
    },
    methods: {
        reloadUser() {
            if(typeof this.sessionKey === 'string') {
                this.apiRequest("GET", "/account")
                    .then(async cnt => {
                        if(cnt.picture !== 0) {
                            const fileObject = await this.apiRequest("GET", "/file-upload/" + cnt.picture, null, true, false, true);
                            cnt.image = URL.createObjectURL(await fileObject.blob());
                        }
                        this.$store.commit("setUser", cnt);
                    })
                    .catch(err => console.log(err));
                return true;
            }
            return false;
        },
        destroySession() {
            this.$cookies.remove("sessionKey");
            this.$store.state.reevaluateLogin++;
        },
        setSession(key) {
            this.$cookies.set("sessionKey", key, 0);
            this.$store.state.reevaluateLogin++;
        },
    }


}