<!--suppress CssUnusedSymbol -->
<template>
  <div v-if="data!==null&&data!==false" :class="'table-wrapper ' + (this.class ?? '')">
    <div class="table-filters-container" v-if="Array.isArray(filters)||Array.isArray(selectOptions)">
      <div class="table-filters">
        <FilterSearchBar
            :showSearchBar="this.filters !== undefined"
            :filters="this.filters"
            :select-options="this.selectOptions"
            :table="this"
            @filtersChanged="onFiltersChanged"
        />
      </div>
      <div :id="'table-inner-wrapper'+this.id" class="table-inner-wrapper" v-if="getFilteredData.length>0">
        <table>
          <thead>
          <tr>
            <th v-if="Array.isArray(selectOptions)" class="table-head-cell table-checkbox-column">
              <CheckBox @valueChanged="masterCheckboxChanged" :inline="true" />
            </th>
            <th v-for="column in columns" :class="column" @click="() => sortData(column)">
            <span class="table-header-wrapper">
            {{ columnNames[column] }}
            <span :class="'table-sorting-indicator '+(sortKey===column?(sortAsc?'asc active':'desc active'):'asc')"
                  v-html="getSVG('caret_down')"
            />
            </span>
            </th>
            <th v-if="(buttons!==undefined&&buttons.length>0) || (customRowButtons!==undefined&&customRowButtons.length>0)"
                :style="getButtonColStyle"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="i in getData.length"
              :data-id="getData[i - 1].id"
              :class="(getRowClass ? getRowClass(getData[i - 1]) : '') + (getData[i - 1].highlighted ? 'highlighted' :'')">
            <td v-if="Array.isArray(selectOptions)" class="table-checkbox-column">
              <CheckBox class="multi-select-checkbox" @valueChanged="singleCheckboxChanged" :inline="true" />

            </td>
            <td v-for="column in columns" :class="column">
              <a @click="onClickLink" class="table-link table-cell"
                 v-if="typeof links === 'object' && links.hasOwnProperty(column)"
                 :href="links[column][i - 1 + this.getPaginationOffset]" :target="linksNewTab ? '_blank' : ''">
                <img alt="Preview image of file" @error="getFilePreview" class="table-preview-image"
                     v-if="typeof previewImages === 'object' && previewImages.hasOwnProperty(column)"
                     :src="previewImages[column][i - 1 + this.getPaginationOffset]">
                <span class="table-cell-text" v-html="getData[i - 1][column]" /></a>
              <span v-else class="table-cell">
              <img alt="Preview image of file" @error="getFilePreview" class="table-preview-image"
                   v-if="typeof previewImages === 'object' && previewImages.hasOwnProperty(column)"
                   :src="previewImages[column][i - 1 + this.getPaginationOffset]">
              <span class="table-cell-text" v-html="getData[i - 1][column]" />
            </span>
            </td>
            <td v-if="customRowButtons!==undefined&&customRowButtons.length>0" :style="getButtonColStyle(i - 1)"
                class="button-col">
              <button v-for="button in customRowButtons[i  -1]"
                      :class="getButtonClassList(button.class)"
                      @click.prevent="button.action(getData[i -1]['id']??null)"
                      v-html="getSVG(button.icon)"
                      :disabled="!checkButtonVisibility(button,getData[i - 1])"
              />
            </td>
            <td v-else-if="buttons!==undefined&&buttons.length>0" :style="getButtonColStyle()" class="button-col">
              <button v-for="button in buttons"
                      :class="getButtonClassList(button.class)"
                      @click.prevent="button.action(getData[i -1]['id']??null)"
                      v-html="getSVG(button.icon)"
                      :disabled="!checkButtonVisibility(button,getData[i - 1])"
              />
            </td>
          </tr>
          </tbody>
        </table>
        <div class="table-buttons">
          <div v-if="(getFilteredData.length>count)||(getFilteredData.length>count)"
               class="table-buttons-left"
          >
            <button :class="'button-pagination' + (startPage  <= 1 ? ' disabled' : '')"
                    data-p="Vorherige"
                    @click.prevent="changePage"
            >
              <span v-html="getSVG('caret_left')" />
            </button>
            <button v-for="p in pagination"
                    :class="'button-pagination '+(p==='...'?'disabled':'')+' '+(startPage===p?'current':'')"
                    :data-p="p"
                    @click.prevent="changePage"
            >
              {{ p }}
            </button>
            <button :class="'button-pagination' + ((startPage*count)>=getFilteredData.length ? ' disabled' : '')"
                    data-p="Nächste"
                    @click.prevent="changePage"
            >
              <span v-html="getSVG('caret_right')" />
            </button>
          </div>

          <div v-if="getFilteredData.length>20" class="table-buttons-right">
            <button :class="'button-pagination '+(count===20?'current':'')" @click.prevent="changePageCount(20);">20
            </button>
            <button :class="'button-pagination '+(count===50?'current':'')" @click.prevent="changePageCount(50);">50
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="data===null">Loading...</div>
  <div v-if="tableNotice.visible">{{ tableNotice.content }}</div>
</template>

<!--suppress JSUnusedGlobalSymbols -->
<script>
import {v4 as uuid4} from "uuid";
import CheckBox from "@/components/elements/CheckBox.vue";
import FilterSearchBar from "@/components/elements/FilterSearchBar.vue";

export default {
  data() {
    return {
      id: null,
      startPage: 1,
      filtered: [],
      sortKey: null,
      sortAsc: true,
      selectedEverything: false,
      preselectedCheckboxes: [],
    }
  },
  props: [
    "columns",
    "data",
    "columnNames",
    "buttons",
    "customRowButtons",
    "getRowClass",
    "filters",
    "selectOptions",
    "links",
    "previewImages",
    "count",
    "linksNewTab",
    "class",
    "showSearchBar"
  ],
  components: {FilterSearchBar, CheckBox},
  mounted() {
    this.id = uuid4();
  },
  computed: {
    getFilteredData() {
      let origData = this.data;

      let cleanedFilters = {};
      for(const filter of this.filtered) {
        if(!(filter.field in cleanedFilters)) {
          cleanedFilters[filter.field] = [];
        }
        cleanedFilters[filter.field].push(filter.value);
      }

      return origData.filter((el) => {
        let res = true;
        for(const field in cleanedFilters) {
          if(!cleanedFilters[field].includes(el[field])) {
            res = false;
          }
        }
        return res;
      });
    },
    getData() {
      let start = (this.startPage - 1) * this.count;
      let end = start + this.count;

      let data = this.getFilteredData;

      let key = this.sortKey;
      let asc = this.sortAsc;
      if(key !== null) {
        data.sort(function(a, b) {
          if(a[key] < b[key]) {
            return asc ? -1 : 1;
          }
          if(a[key] > b[key]) {
            return asc ? 1 : -1;
          }
          return 0;
        });
      }
      return data.slice(start, end);
    },
    getPaginationOffset() {
      return (this.startPage - 1) * this.count;
    },
    tableNotice() {
      if(this.data === false) {
        return {
          visible: true,
          content: 'Fehler beim Laden der Daten!',
        }
      }
      if(this.data !== null && this.data !== false && this.getFilteredData.length === 0) {
        return {
          visible: true,
          content: 'Keine Einträge gefunden!',
        }
      }
      return {};
    },
    pagination() {
      let pagination = [];
      let page = this.startPage;
      let lastPage = Math.ceil(this.getFilteredData.length / this.count);
      for(let i = 1; i <= lastPage; i++) {
        let show = false;
        if(i === 1) {
          show = true;
        }

        if(i === lastPage) {
          show = true;
        }

        if(i === page) {
          show = true;
        }

        if(i === page - 1 || i === page + 1) {
          show = true;
        }

        if((i === (page - 2) && (page - 2) !== 1)) {
          if((page - 3) === 1) {
            show = true;
          } else {
            pagination.push('...');
          }
        }

        if((i === (page + 2) && (page + 2) !== lastPage)) {
          if((page + 3) === lastPage) {
            show = true;
          } else {
            pagination.push('...');
          }
        }

        if(show) {
          pagination.push(i);
        }
      }
      return pagination;
    },

  },
  methods: {
    onFiltersChanged(filtered) {
      this.filtered = filtered;
    },
    getButtonColStyle(i) {
      const buttons = this.customRowButtons ? this.customRowButtons[i] : this.buttons;
      const buttonCount = (buttons !== undefined) ? buttons.length : 0;
      const buttonPadding = 5;
      const buttonWidth = 32;
      const width = buttonCount * buttonWidth + (buttonCount - 1) * buttonPadding + 2 * 5 + 1
      return 'min-width:' + width + 'px;width:' + width + 'px;box-sizing:border-box;';
    },
    onClickLink(event) {
      const target = event.target.closest("a");
      if(target.dataset.prevent === 'true') {
        event.preventDefault();
      }
    },
    getFilePreview(event) {
      event.target.src = require('@/assets/img/file.svg');
    },
    changePageCount(count) {
      this.startPage = 1;
      this.count = parseInt(count, 10);
    },
    changePage(event) {
      const el = event.target.closest(".button-pagination");
      if(!el) {
        return;
      }
      if(el.classList.contains('disabled')) {
        return;
      }
      let page = el.dataset.p;
      if(page === '...') {
        return;
      }

      let wrapper = document.getElementById('table-inner-wrapper' + this.id);

      this.selectedEverything = false;
      this.preselectedCheckboxes = [];
      const rows = wrapper.querySelectorAll("tr");
      const checkbox = wrapper.querySelector(".table-head-cell.table-checkbox-column input[type=checkbox]");
      if(checkbox)
        checkbox.checked = false;
      for(const row of rows) {
        const checkbox = row.querySelector("td:first-child input[type=checkbox]");
        if(checkbox === null) continue;
        checkbox.checked = this.preselectedCheckboxes.includes(checkbox);
      }

      if(page === 'Vorherige') {
        page = this.startPage - 1;
      }
      if(page === 'Nächste') {
        page = this.startPage + 1;
      }
      this.startPage = parseInt(page, 10);
    },
    getButtonClassList(buttonClass) {
      return (buttonClass ?? '') + " button-table";
    },
    checkButtonVisibility(button, rowData) {
      if("if" in button) {
        return button.if(rowData);
      }
      return true;
    },
    executeMultiAction(option) {
      const ids = [];
      const els = document.getElementsByClassName("multi-select-checkbox");
      for(const el of els) {
        if(el.checked) {
          ids.push(el.closest("tr").dataset.id);
        }
      }
      option.action(ids);
    },
    addFilter(filter, option) {
      const current = this.filtered.find(item => item.value === option);
      if(current === undefined) {
        this.filtered.push({name: filter.name, field: filter.field, value: option});
      } else {
        this.filtered = this.filtered.filter(item => item.value !== option);
      }
    },
    removeFilter(filter) {
      this.filtered = this.filtered.filter(item => item !== filter);
    },
    toggleFilter(event) {
      if(event.target) {
        let target = event.target
        target = target.closest('.table-filter');
        for(let filterElement of document.querySelectorAll(".table-filter")) {
          let next = filterElement.querySelector(".custom-select-box-list");
          if(next !== target)
            next.classList.remove("open");
        }
        target.querySelector(".table-filter-options").classList.toggle("open");
      }
      this.updateMouseCursor(event);
    },
    sortData(field) {
      if(this.sortKey === field) {
        this.sortAsc = !this.sortAsc;
      } else {
        this.sortKey = field;
        this.sortAsc = true;
      }
    },
    singleCheckboxChanged(value, event) {
      let wrapper = document.getElementById('table-inner-wrapper' + this.id);
      if(!value) {
        if(this.selectedEverything) {
          this.selectedEverything = false;
          wrapper.querySelector("th.table-head-cell input[type=checkbox]").checked = false;
        }
      }
    },
    masterCheckboxChanged(value, event) {
      let wrapper = document.getElementById('table-inner-wrapper' + this.id);
      if(value) {
        this.selectedEverything = true;
        this.preselectedCheckboxes = [];
        const rows = wrapper.querySelectorAll("tr");
        for(const row of rows) {
          const checkbox = row.querySelector("td:first-child input[type=checkbox]");
          if(checkbox === null) continue;
          if(checkbox.checked) {
            this.preselectedCheckboxes.push(checkbox);
          }
          checkbox.checked = true;
        }
      } else {
        this.selectedEverything = false;
        const rows = wrapper.querySelectorAll("tr");
        for(const row of rows) {
          const checkbox = row.querySelector("td:first-child input[type=checkbox]");
          if(checkbox === null) continue;
          checkbox.checked = false;
        }
      }
    },
  }
}
</script>