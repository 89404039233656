<template>
  <!-- ToDo: Textarea! -->
  <div :class="className">
<textarea :id="'textArea_'+id"
          v-model="valueModel"
          :data-enum="getEnum"
          :data-max="max"
          :data-min="min"
          :data-not-in="notIn"
          :data-custom-valid="customValidateValid"
          :data-has-custom-validation="this.customValidate !== undefined && typeof this.customValidate === 'function'"
          :name="name"
          class="input input-textarea"
          placeholder=" "
          @focusout="validate"
          @keyup="validate"
          :autocomplete="autocomplete??'on'"
/>
    <label :for="'textArea_'+id">{{ placeholder }}</label>
  </div>
</template>
<script>
import {v4 as uuid4} from "uuid";
import {nextTick} from "vue";

export default {
  data() {
    return {
      valid: true,
      id: null,
      fullHTMLId: null,
      customValidateValid: null,
      valueModel: '',
    }
  },
  props: [
    "name",
    "placeholder",
    "value",
    "class",
    "min",
    "max",
    "enum",
    "notIn",
    "formValidate",
    "type",
    "autocomplete",
    "customValidate",
    "customValidateInitial",
  ],
  mounted() {
    this.id = uuid4();
    this.fullHTMLId = "textArea_" + this.id;
    this.valueModel = this.value;
    if (typeof this.customValidateInitial === "boolean") {
      this.customValidateValid = this.customValidateInitial;
    }
  },
  watch: {
    value(val, ignored_oldVal) {
      this.valueModel = val;
    }
  },
  methods: {
    async validate(event, noChange = false) {
      const el = event.target;
      const value = el.value;
      if (!noChange) {
        this.$emit("value-changed", value);
      }
      if (this.formValidate !== undefined && this.formValidate !== null && this.formValidate === false) {
        return;
      }

      let valid = true;

      if (this.customValidate !== undefined && typeof this.customValidate === 'function') {
        if (this.customValidate(value)) {
          this.customValidateValid = true;
        } else {
          valid = false;
          this.customValidateValid = false;
        }
      }

      await nextTick();

      this.globalValidate(event);

      if (this.min !== undefined) {
        if (value.length < this.min) {
          valid = false;
        }
      }
      if (this.max !== undefined) {
        if (value.length > this.max) {
          valid = false;
        }
      }
      if (this.enum !== undefined) {
        if (this.enum.includes(value)) {
          valid = false;
        }
      }
      if (this.notIn !== undefined) {
        if (this.notIn.includes(val)) {
          valid = false;
        }
      }
      this.valid = valid;
    }
  },
  computed: {
    className() {
      let className = this.class ?? '';
      if (this.valid !== undefined && !this.valid) {
        className += ' invalid';
      }
      return className + ' input-wrapper';
    },
    getEnum() {
      return this.enum;
    },
  }
}
</script>