<template>
  <div class="header-bar">
    <div class="header-bar-left">
      <div class="header-bar-left-greeting">Willkommen, {{ $store.state?.user?.firstName }}!</div>
      <div class="header-bar-left-date" v-html="getCurrentDate" />
    </div>
    <div class="header-bar-search-bar-container">
      <input type="text" class="header-bar-search-bar" placeholder="Suchen..." />
      <span class="header-bar-search-icon" v-html="getSVG('search')" />
      <span class="header-bar-ticket-icon" v-html="getSVG('ticket')" />
    </div>

    <div class="header-bar-right">
      <div class="header-bar-next-appointment">
        <span v-html="getSVG('calendar')"></span>
        <div class="header-bar-next-appointment-text">
          <div class="header-bar-next-appointment-title"><b>08:30-09:00</b></div>
          <div class="header-bar-next-appointment-subtitle">Bereitstellung Mustermann</div>
        </div>
      </div>
      <div class="header-bar-right-icons">
        <span v-html="getSVG('header_success')" style="cursor: pointer" @click=toggleFilter />
        <span v-html="getSVG('bell')" />
      </div>
    </div>
    <HeaderBarBox :visibility=this.displayBox></HeaderBarBox>
    </div>
</template>

<script>
import HeaderBarBox from "@/components/elements/HeaderBarBox.vue";

export default {
  components: {HeaderBarBox},
  data() {
    return {
      displayBox: false
    }
  },
  computed: {
    getCurrentDate() {
      let date = new Date();
      const options = {weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'};
      return date.toLocaleDateString('de-DE', options);
    }
  },
  methods: {
    toggleFilter() {
      this.displayBox = !this.displayBox;
    },
  }
}
</script>
