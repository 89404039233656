<template>
  <div class="parted-page-static">
    <slot></slot>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
}
</script>