export default {
    mounted() {
        window.addEventListener('click', this.windowClick);
    },
    unmounted() {
        window.removeEventListener('click', this.windowClick);
    },
    methods: {
        windowClick(e) {
            if (e.target.closest('.table-filter') == null && e.target.closest(".prevent-closing") == null) {
                document.querySelectorAll(".table-filter-options.open").forEach(options => {
                    options.classList.remove("open");
                });
            }
        },
    }


}