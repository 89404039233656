<template v-if="this.tree.list != null">
  <div :class="item.type" v-for="item in this.tree">

    <div class="knowledge-link-title-container">

    <span v-if="item.type === 'category'" class="knowledge-sidebar-arrows" @click="onClickCategory"
          v-html="getSVG('caret_down')"></span>

      <router-link :to="'/knowledge/' + (item.type === 'entry' ? 'manage/' : '') +item.id"
                   v-if="item.type === 'category'" class="category"
                   @click.stop >{{ item.title }}

      </router-link>
      <router-link :to="'/knowledge/' + (item.type === 'entry' ? 'manage/' : '') +item.id" v-if="item.type === 'entry'"
                   class="entry"
                   @click.prevent="this.emitter.emit('load-entry');" >{{ item.title }}
      </router-link>
    </div>

    <div v-if="item.type === 'category'" class="category-content">
      <KnowledgeTree :tree="item.children"></KnowledgeTree>
    </div>
  </div>
</template>
<script>


export default {
  name: "KnowledgeTree",
  props: [
    "tree"
  ],
  watch: {
    '$route.params.entryId'(newVal) {
      console.log(newVal);
      console.log(newVal);
      console.log('Entry ID changed to: ' + newVal);
    },

    tree(old, newval) {
    },
  },
  components: {},
  mounted() {},
  methods: {
    onClickCategory(event) {
      let target = event.target;
      if(!target.classList.contains('category')) {
        target = target.closest(".category");
      }

      event.stopPropagation();
      target.classList.toggle("open");
    }
  }
}
</script>

<style scoped>

</style>