<template>
    <div class="login-container">
        <div class="background">
          <div class="background-left"></div>
          <div class="background-right">
            <div class="login">
              <span class="logo" v-html="getSVGImage('logo.svg')" />
              <h3>Telenec Ticketsystem</h3>
              <div class="login-inner-container">
                <form @submit.prevent="getCredentials" id="loginForm" class="login-inputFields">
                  <TextInput type="text" name="user" placeholder="Benutzername" />
                  <TextInput type="password" name="password" placeholder="Passwort" />
                  <div class="login-error" v-if="error">Benutzername oder Passwort falsch!</div>
                  <button type="submit" style="height: 48px;">Login</button>
                  <p><a>Passwort vergessen?</a></p>
                </form>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>

import TextInput from "@/components/elements/TextInput.vue";

export default {
    data() {
        return {
            error: false,
        }
    },
    components: {TextInput},
    mounted() {
        this.$store.commit("setPageTitle", "Login");
        this.setBreadCrumbs([]);
    },
    methods: {
        async getCredentials() {
            const data = this.getFormData('loginForm');
            let res = await this.apiRequest('POST', '/account/login', data, false);
            if(res.success) {
                this.setSession(res.token);
                this.$store.state.user.capabilities = res.capabilities;
            } else {
                this.error = true;
            }
        }
    }
}
</script>