<template>
  <button class="button button-primary negative" :disabled="this.disabled">{{ text }}</button>
</template>
<script>
export default {
  props: [
    "text",
      "disabled"
  ],
}
</script>